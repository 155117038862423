import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_FILE_INFO,
  REQUEST_DETAIL_FILE_INFO_SUCCESS,
  REQUEST_DETAIL_FILE_INFO_ERROR,
  REQUEST_DETAIL_FILE_PAGE_SET,
  REQUEST_DETAIL_FILE_EDIT_MODAL_SET,
  REQUEST_DETAIL_FILE_EDIT_CHANGE,
  REQUEST_DETAIL_FILE_UPDATE,
  REQUEST_DETAIL_FILE_UPDATE_SUCCESS,
  REQUEST_DETAIL_FILE_UPDATE_ERROR
} from '../actions';

export const NAME = 'request_detail_file';

export const RequestDetailFileInfo = (data) => ({
  type: REQUEST_DETAIL_FILE_INFO,
  payload: data
});

export const RequestDetailFileInfoSuccess = (data) => ({
  type: REQUEST_DETAIL_FILE_INFO_SUCCESS,
  payload: data
});

export const RequestDetailFileInfoError = (error) => ({
  type: REQUEST_DETAIL_FILE_INFO_ERROR,
  payload: error
});

export const RequestDetailFilePageSet = (data) => ({
  type: REQUEST_DETAIL_FILE_PAGE_SET,
  payload: data
});

export const RequestDetailFileEditModalSet = (data) => ({
  type: REQUEST_DETAIL_FILE_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailFileEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_FILE_EDIT_CHANGE,
  payload: data
});

export const RequestDetailFileUpdate = (data) => ({
  type: REQUEST_DETAIL_FILE_UPDATE,
  payload: data
});

export const RequestDetailFileUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_FILE_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailFileUpdateError = (error) => ({
  type: REQUEST_DETAIL_FILE_UPDATE_ERROR,
  payload: error
});

const requestDetailFile = (state) => state[NAME];

export const RequestDetailFileSelector = createStructuredSelector({
  requestDetailFile
})
