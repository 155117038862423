import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_DETAIL_HEALTH_REGISTRY_LIST,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_HEALTH_REGISTRY_ADD,
  REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL,
  REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE,
} from '../actions';
import {
  RequestDetailHealthRegistryListSuccess,
  RequestDetailHealthRegistryListError,
  RequestDetailHealthRegistrySearchGeoCountryListSuccess,
  RequestDetailHealthRegistrySearchGeoCountryListError,
  RequestDetailHealthRegistrySearchHealthAgencyListSuccess,
  RequestDetailHealthRegistrySearchHealthAgencyListError,
  RequestDetailHealthRegistryAddSuccess,
  RequestDetailHealthRegistryAddError,
  RequestDetailHealthRegistryDetailSuccess,
  RequestDetailHealthRegistryDetailError,
  RequestDetailHealthRegistryUpdateSuccess,
  RequestDetailHealthRegistryUpdateError
} from '../actions';
 
export function* watchRequestDetailHealthRegistryList() {
  yield takeEvery(REQUEST_DETAIL_HEALTH_REGISTRY_LIST, onRequestDetailHealthRegistryList);
}

export function* watchRequestDetailHealthRegistrySearchHealthAgencyList() {
  yield takeEvery(REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST, onRequestDetailHealthRegistrySearchHealthAgencyList);
}

export function* watchRequestDetailHealthRegistrySearchGeoCountryList() {
  yield takeEvery(REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST, onRequestDetailHealthRegistrySearchGeoCountryList);
}

export function* watchRequestDetailHealthRegistryAdd() {
  yield takeEvery(REQUEST_DETAIL_HEALTH_REGISTRY_ADD, onRequestDetailHealthRegistryAdd);
}

export function* watchRequestDetailHealthRegistryDetail() {
  yield takeEvery(REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL, onRequestDetailHealthRegistryDetail);
}

export function* watchRequestDetailHealthRegistryUpdate() {
  yield takeEvery(REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE, onRequestDetailHealthRegistryUpdate);
}

const onRequestDetailHealthRegistryListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_health_registry/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailHealthRegistryUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_health_registry/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}


const onRequestDetailHealthRegistryAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_health_registry/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailHealthRegistrySearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailHealthRegistrySearchHealthAgencyListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/health_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailHealthRegistryDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_health_registry/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestDetailHealthRegistryList(data) {
  try {
    const response = yield call(onRequestDetailHealthRegistryListAsync, data.payload);
    yield put(RequestDetailHealthRegistryListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailHealthRegistryListError(error));
  }
}

function* onRequestDetailHealthRegistrySearchHealthAgencyList(data) {
  try {
    const response = yield call(onRequestDetailHealthRegistrySearchHealthAgencyListAsync, data.payload);
    yield put(RequestDetailHealthRegistrySearchHealthAgencyListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailHealthRegistrySearchHealthAgencyListError(error));
  }
}

function* onRequestDetailHealthRegistrySearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestDetailHealthRegistrySearchGeoCountryListAsync, data.payload);
    yield put(RequestDetailHealthRegistrySearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailHealthRegistrySearchGeoCountryListError(error));
  }
}

function* onRequestDetailHealthRegistryAdd(data) {
  try {
      const response = yield call(onRequestDetailHealthRegistryAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailHealthRegistryAddSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailHealthRegistryAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetailHealthRegistryDetail(data) {
  try {
    const response = yield call(onRequestDetailHealthRegistryDetailAsync, data.payload);
    yield put(RequestDetailHealthRegistryDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailHealthRegistryDetailError(error));
  }
}

function* onRequestDetailHealthRegistryUpdate(data) {
  try {
      const response = yield call(onRequestDetailHealthRegistryUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailHealthRegistryUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailHealthRegistryUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailHealthRegistryList),
    fork(watchRequestDetailHealthRegistrySearchGeoCountryList),
    fork(watchRequestDetailHealthRegistrySearchHealthAgencyList),
    fork(watchRequestDetailHealthRegistryAdd),
    fork(watchRequestDetailHealthRegistryDetail),
    fork(watchRequestDetailHealthRegistryUpdate),
  ]);
} 