import { createStructuredSelector } from 'reselect'

import {
  REQUEST_MESSAGE_LIST,
  REQUEST_MESSAGE_LIST_SUCCESS,
  REQUEST_MESSAGE_LIST_ERROR,
  REQUEST_MESSAGE_PAGE_SET,
  REQUEST_MESSAGE_SEARCH_SET,
  REQUEST_MESSAGE_SEARCH_CHANGE,
  REQUEST_MESSAGE_SEARCH_REQUEST_LIST,
  REQUEST_MESSAGE_SEARCH_REQUEST_LIST_SUCCESS,
  REQUEST_MESSAGE_SEARCH_REQUEST_LIST_ERROR,
  REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_MESSAGE_ADD_SET,
  REQUEST_MESSAGE_ADD_MODAL_SET,
  REQUEST_MESSAGE_ADD,
  REQUEST_MESSAGE_ADD_SUCCESS,
  REQUEST_MESSAGE_ADD_ERROR,
  REQUEST_MESSAGE_ADD_CHANGE,
  REQUEST_MESSAGE_DETAIL,
  REQUEST_MESSAGE_DETAIL_SUCCESS,
  REQUEST_MESSAGE_DETAIL_ERROR,
  REQUEST_MESSAGE_VIEW_MODAL_SET,
  REQUEST_MESSAGE_EDIT_CHANGE,
  REQUEST_MESSAGE_EDIT_MODAL_SET,
  REQUEST_MESSAGE_UPDATE,
  REQUEST_MESSAGE_UPDATE_SUCCESS,
  REQUEST_MESSAGE_UPDATE_ERROR,
  REQUEST_MESSAGE_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_message';

export const RequestMessageList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_MESSAGE_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestMessageListSuccess = (data) => ({
  type: REQUEST_MESSAGE_LIST_SUCCESS,
  payload: data
});

export const RequestMessageListError = (error) => ({
  type: REQUEST_MESSAGE_LIST_ERROR,
  payload: error
});

export const RequestMessagePageSet = (data) => ({
  type: REQUEST_MESSAGE_PAGE_SET,
  payload: data
});

export const RequestMessageSearchSet = (data) => ({
  type: REQUEST_MESSAGE_SEARCH_SET,
  payload: data
});

export const RequestMessageSearchChangeSet = (data) => ({
  type: REQUEST_MESSAGE_SEARCH_CHANGE,
  payload: data
});

export const RequestMessageSearchRequestList = (data) => ({
  type: REQUEST_MESSAGE_SEARCH_REQUEST_LIST,
  payload: data
});

export const RequestMessageSearchRequestListSuccess = (message) => ({
  type: REQUEST_MESSAGE_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const RequestMessageSearchRequestListError = (error) => ({
  type: REQUEST_MESSAGE_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const RequestMessageSearchGeoCountryList = (data) => ({
  type: REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestMessageSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestMessageSearchGeoCountryListError = (error) => ({
  type: REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const RequestMessageAddChangeSet = (data) => ({
  type: REQUEST_MESSAGE_ADD_CHANGE,
  payload: data
});

export const RequestMessageAddSet = (data) => ({
  type: REQUEST_MESSAGE_ADD_SET,
  payload: data
});

export const RequestMessageAddModalSet = (data) => ({
  type: REQUEST_MESSAGE_ADD_MODAL_SET,
  payload: data
});

export const RequestMessageAdd = (data) => ({
  type: REQUEST_MESSAGE_ADD,
  payload: data
});

export const RequestMessageAddSuccess = (message) => ({
  type: REQUEST_MESSAGE_ADD_SUCCESS,
  payload: message
});

export const RequestMessageAddError = (error) => ({
  type: REQUEST_MESSAGE_ADD_ERROR,
  payload: error
});

export const RequestMessageDetail = (data) => ({
  type: REQUEST_MESSAGE_DETAIL,
  payload: data
});

export const RequestMessageDetailSuccess = (data) => ({
  type: REQUEST_MESSAGE_DETAIL_SUCCESS,
  payload: data
});

export const RequestMessageDetailError = (error) => ({
  type: REQUEST_MESSAGE_DETAIL_ERROR,
  payload: error
});

export const RequestMessageViewModalSet = (data) => ({
  type: REQUEST_MESSAGE_VIEW_MODAL_SET,
  payload: data
});

export const RequestMessageEditModalSet = (data) => ({
  type: REQUEST_MESSAGE_EDIT_MODAL_SET,
  payload: data
});

export const RequestMessageEditChangeSet = (data) => ({
  type: REQUEST_MESSAGE_EDIT_CHANGE,
  payload: data
});

export const RequestMessageUpdate = (data) => ({
  type: REQUEST_MESSAGE_UPDATE,
  payload: data
});

export const RequestMessageUpdateSuccess = (message) => ({
  type: REQUEST_MESSAGE_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestMessageUpdateError = (error) => ({
  type: REQUEST_MESSAGE_UPDATE_ERROR,
  payload: error
});

export const RequestMessageViewTabSet = (data) => ({
  type: REQUEST_MESSAGE_VIEW_TAB_SET,
  payload: data
});

const requestMessage = (state) => state[NAME];

export const RequestMessageSelector = createStructuredSelector({
  requestMessage
})
