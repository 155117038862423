import { createStructuredSelector } from 'reselect'

import {
  PROCESS_LIST,
  PROCESS_LIST_SUCCESS,
  PROCESS_LIST_ERROR,
  PROCESS_SEARCH_SET,
  PROCESS_SEARCH_GEO_COUNTRY_LIST,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR,
  PROCESS_SEARCH_PROCESS_STATE_LIST,
  PROCESS_SEARCH_PROCESS_STATE_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_STATE_LIST_ERROR,
  PROCESS_SEARCH_PROCESS_TYPE_LIST,
  PROCESS_SEARCH_PROCESS_TYPE_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_TYPE_LIST_ERROR,
} from '../actions';

export const NAME = 'process';

export const ProcessList = (data) => ({
  type: PROCESS_LIST,
  payload: data
});

export const ProcessListSuccess = (data) => ({
  type: PROCESS_LIST_SUCCESS,
  payload: data 
});

export const ProcessListError = (error) => ({
  type: PROCESS_LIST_ERROR,
  payload: error
});


export const ProcessSearchSet = (data) => ({
  type: PROCESS_SEARCH_SET,
  payload: data
});

export const ProcessSearchGeoCountryList = (data) => ({
  type: PROCESS_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const ProcessSearchGeoCountryListSuccess = (data) => ({
  type: PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: data
});

export const ProcessSearchGeoCountryListError = (error) => ({
  type: PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
});

export const ProcessSearchProcessStateList = (data) => ({
  type: PROCESS_SEARCH_PROCESS_STATE_LIST,
  payload: data
});

export const ProcessSearchProcessStateListSuccess = (data) => ({
  type: PROCESS_SEARCH_PROCESS_STATE_LIST_SUCCESS,
  payload: data
});

export const ProcessSearchProcessStateListError = (error) => ({
  type: PROCESS_SEARCH_PROCESS_STATE_LIST_ERROR,
  payload: error
});

export const ProcessSearchProcessTypeList = (data) => ({
  type: PROCESS_SEARCH_PROCESS_TYPE_LIST,
  payload: data
});

export const ProcessSearchProcessTypeListSuccess = (data) => ({
  type: PROCESS_SEARCH_PROCESS_TYPE_LIST_SUCCESS,
  payload: data
});

export const ProcessSearchProcessTypeListError = (error) => ({
  type: PROCESS_SEARCH_PROCESS_TYPE_LIST_ERROR,
  payload: error
});

const process = (state) => state[NAME];

export const ProcessSelector = createStructuredSelector({
  process
})
