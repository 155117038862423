/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const LOGIN_PASSWORD = 'LOGIN_PASSWORD';
export const LOGIN_PASSWORD_SUCCESS = 'LOGIN_PASSWORD_SUCCESS';
export const LOGIN_PASSWORD_ERROR = 'LOGIN_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* PROCESS */
export const PROCESS_LIST = 'PROCESS_LIST';
export const PROCESS_LIST_SUCCESS = 'PROCESS_LIST_SUCCESS';
export const PROCESS_LIST_ERROR = 'PROCESS_LIST_ERROR';
export const PROCESS_SEARCH_SET= 'PROCESS_SEARCH_SET';
export const PROCESS_SEARCH_GEO_COUNTRY_LIST = 'PROCESS_SEARCH_GEO_COUNTRY_LIST';
export const PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'PROCESS_SEARCH_GEO_COUNTRY_SUCCESS';
export const PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR = 'PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS';
export const PROCESS_SEARCH_PROCESS_STATE_LIST = 'PROCESS_SEARCH_PROCESS_STATE_LIST';
export const PROCESS_SEARCH_PROCESS_STATE_LIST_SUCCESS = 'PROCESS_SEARCH_PROCESS_STATE_SUCCESS';
export const PROCESS_SEARCH_PROCESS_STATE_LIST_ERROR = 'PROCESS_SEARCH_PROCESS_STATE_LIST_SUCCESS';
export const PROCESS_SEARCH_PROCESS_TYPE_LIST = 'PROCESS_SEARCH_PROCESS_TYPE_LIST';
export const PROCESS_SEARCH_PROCESS_TYPE_LIST_SUCCESS = 'PROCESS_SEARCH_PROCESS_TYPE_SUCCESS';
export const PROCESS_SEARCH_PROCESS_TYPE_LIST_ERROR = 'PROCESS_SEARCH_PROCESS_TYPE_LIST_SUCCESS';

/* REQUEST */
export const REQUEST_INFO = 'REQUEST_INFO';
export const REQUEST_INFO_SUCCESS = 'REQUEST_INFO_SUCCESS';
export const REQUEST_INFO_ERROR = 'REQUEST_INFO_ERROR';
export const REQUEST_PAGE_SET = 'REQUEST_PAGE_SET';
export const REQUEST_EDIT_MODAL_SET = 'REQUEST_EDIT_MODAL_SET';
export const REQUEST_EDIT_CHANGE = 'REQUEST_EDIT_CHANGE';
export const REQUEST_UPDATE = 'REQUEST_UPDATE';
export const REQUEST_UPDATE_SUCCESS = 'REQUEST_UPDATE_SUCCESS';
export const REQUEST_UPDATE_ERROR = 'REQUEST_UPDATE_ERROR';

/* REQUEST_DETAIL */
export const REQUEST_DETAIL_INFO = 'REQUEST_DETAIL_INFO';
export const REQUEST_DETAIL_INFO_SUCCESS = 'REQUEST_DETAIL_INFO_SUCCESS';
export const REQUEST_DETAIL_INFO_ERROR = 'REQUEST_DETAIL_INFO_ERROR';
export const REQUEST_DETAIL_PAGE_SET = 'REQUEST_DETAIL_PAGE_SET';
export const REQUEST_DETAIL_EDIT_MODAL_SET = 'REQUEST_DETAIL_EDIT_MODAL_SET';
export const REQUEST_DETAIL_EDIT_CHANGE = 'REQUEST_DETAIL_EDIT_CHANGE';
export const REQUEST_DETAIL_UPDATE = 'REQUEST_DETAIL_UPDATE';
export const REQUEST_DETAIL_UPDATE_SUCCESS = 'REQUEST_DETAIL_UPDATE_SUCCESS';
export const REQUEST_DETAIL_UPDATE_ERROR = 'REQUEST_DETAIL_UPDATE_ERROR';

/* SEARCH */
export const SEARCH_GEO_COUNTRY = 'SEARCH_GEO_COUNTRY';
export const SEARCH_GEO_COUNTRY_SUCCESS = 'SEARCH_GEO_COUNTRY_SUCCESS';
export const SEARCH_GEO_COUNTRY_ERROR = 'SEARCH_GEO_COUNTRY_ERROR';
export const SEARCH_ENTERPRISE_TYPE = 'SEARCH_ENTERPRISE_TYPE';
export const SEARCH_ENTERPRISE_TYPE_SUCCESS = 'SEARCH_ENTERPRISE_TYPE_SUCCESS';
export const SEARCH_ENTERPRISE_TYPE_ERROR = 'SEARCH_ENTERPRISE_TYPE_ERROR';
export const SEARCH_PRODUCT_TYPE = 'SEARCH_PRODUCT_TYPE';
export const SEARCH_PRODUCT_TYPE_SUCCESS = 'SEARCH_PRODUCT_TYPE_SUCCESS';
export const SEARCH_PRODUCT_TYPE_ERROR = 'SEARCH_PRODUCT_TYPE_ERROR';
export const SEARCH_DELIVERY_TERM = 'SEARCH_DELIVERY_TERM';
export const SEARCH_DELIVERY_TERM_SUCCESS = 'SEARCH_DELIVERY_TERM_SUCCESS';
export const SEARCH_DELIVERY_TERM_ERROR = 'SEARCH_DELIVERY_TERM_ERROR';
export const SEARCH_ARTICLE_GROUP = 'SEARCH_ARTICLE_GROUP';
export const SEARCH_ARTICLE_GROUP_SUCCESS = 'SEARCH_ARTICLE_GROUP_SUCCESS';
export const SEARCH_ARTICLE_GROUP_ERROR = 'SEARCH_ARTICLE_GROUP_ERROR';
export const SEARCH_CURRENCY = 'SEARCH_CURRENCY';
export const SEARCH_CURRENCY_SUCCESS = 'SEARCH_CURRENCY_SUCCESS';
export const SEARCH_CURRENCY_ERROR = 'SEARCH_CURRENCY_ERROR';
export const SEARCH_QUOTE_EXPIRATION = 'SEARCH_QUOTE_EXPIRATION';
export const SEARCH_QUOTE_EXPIRATION_SUCCESS = 'SEARCH_QUOTE_EXPIRATION_SUCCESS';
export const SEARCH_QUOTE_EXPIRATION_ERROR = 'SEARCH_QUOTE_EXPIRATION_ERROR';
export const SEARCH_INCOTERM = 'SEARCH_INCOTERM';
export const SEARCH_INCOTERM_SUCCESS = 'SEARCH_INCOTERM_SUCCESS';
export const SEARCH_INCOTERM_ERROR = 'SEARCH_INCOTERM_ERROR';
export const SEARCH_TRANSPORT_TYPE = 'SEARCH_TRANSPORT_TYPE';
export const SEARCH_TRANSPORT_TYPE_SUCCESS = 'SEARCH_TRANSPORT_TYPE_SUCCESS';
export const SEARCH_TRANSPORT_TYPE_ERROR = 'SEARCH_TRANSPORT_TYPE_ERROR';
export const SEARCH_FILE_TYPE = 'SEARCH_FILE_TYPE';
export const SEARCH_FILE_TYPE_SUCCESS = 'SEARCH_FILE_TYPE_SUCCESS';
export const SEARCH_FILE_TYPE_ERROR = 'SEARCH_FILE_TYPE_ERROR';
export const SEARCH_REQUEST_PROCESS_TYPE = 'SEARCH_REQUEST_PROCESS_TYPE';
export const SEARCH_REQUEST_PROCESS_TYPE_SUCCESS = 'SEARCH_REQUEST_PROCESS_TYPE_SUCCESS';
export const SEARCH_REQUEST_PROCESS_TYPE_ERROR = 'SEARCH_REQUEST_PROCESS_TYPE_ERROR';
export const SEARCH_REQUEST_ARTICLE_TYPE = 'SEARCH_REQUEST_ARTICLE_TYPE';
export const SEARCH_REQUEST_ARTICLE_TYPE_SUCCESS = 'SEARCH_REQUEST_ARTICLE_TYPE_SUCCESS';
export const SEARCH_REQUEST_ARTICLE_TYPE_ERROR = 'SEARCH_REQUEST_ARTICLE_TYPE_ERROR';

/* REQUEST_ANSWER */
export const REQUEST_ANSWER_INFO = 'REQUEST_ANSWER_INFO';
export const REQUEST_ANSWER_INFO_SUCCESS = 'REQUEST_ANSWER_INFO_SUCCESS';
export const REQUEST_ANSWER_INFO_ERROR = 'REQUEST_ANSWER_INFO_ERROR';
export const REQUEST_ANSWER_PAGE_SET = 'REQUEST_ANSWER_PAGE_SET';
export const REQUEST_ANSWER_EDIT_MODAL_SET = 'REQUEST_ANSWER_EDIT_MODAL_SET';
export const REQUEST_ANSWER_EDIT_CHANGE = 'REQUEST_ANSWER_EDIT_CHANGE';
export const REQUEST_ANSWER_UPDATE = 'REQUEST_ANSWER_UPDATE';
export const REQUEST_ANSWER_UPDATE_SUCCESS = 'REQUEST_ANSWER_UPDATE_SUCCESS';
export const REQUEST_ANSWER_UPDATE_ERROR = 'REQUEST_ANSWER_UPDATE_ERROR';

/* UPLOAD FILE */
export const UPLOAD_SET = 'UPLOAD_SET';
export const UPLOAD_SET_SUCCESS = 'UPLOAD_SET_SUCCESS';
export const UPLOAD_SET_ERROR = 'UPLOAD_SET_ERROR';
export const UPLOAD_RESET = 'UPLOAD_RESET';

/* REQUEST_DETAIL_FILE */
export const REQUEST_DETAIL_FILE_INFO = 'REQUEST_DETAIL_FILE_INFO';
export const REQUEST_DETAIL_FILE_INFO_SUCCESS = 'REQUEST_DETAIL_FILE_INFO_SUCCESS';
export const REQUEST_DETAIL_FILE_INFO_ERROR = 'REQUEST_DETAIL_FILE_INFO_ERROR';
export const REQUEST_DETAIL_FILE_PAGE_SET = 'REQUEST_DETAIL_FILE_PAGE_SET';
export const REQUEST_DETAIL_FILE_EDIT_MODAL_SET = 'REQUEST_DETAIL_FILE_EDIT_MODAL_SET';
export const REQUEST_DETAIL_FILE_EDIT_CHANGE = 'REQUEST_DETAIL_FILE_EDIT_CHANGE';
export const REQUEST_DETAIL_FILE_UPDATE = 'REQUEST_DETAIL_FILE_UPDATE';
export const REQUEST_DETAIL_FILE_UPDATE_SUCCESS = 'REQUEST_DETAIL_FILE_UPDATE_SUCCESS';
export const REQUEST_DETAIL_FILE_UPDATE_ERROR = 'REQUEST_DETAIL_FILE_UPDATE_ERROR';

/* REQUEST_GEO_COUNTRY */
export const REQUEST_GEO_COUNTRY_LIST = 'REQUEST_GEO_COUNTRY_LIST';
export const REQUEST_GEO_COUNTRY_LIST_SUCCESS = 'REQUEST_GEO_COUNTRY_LIST_SUCCESS';
export const REQUEST_GEO_COUNTRY_LIST_ERROR = 'REQUEST_GEO_COUNTRY_LIST_ERROR';
export const REQUEST_GEO_COUNTRY_PAGE_SET = 'REQUEST_GEO_COUNTRY_PAGE_SET';
export const REQUEST_GEO_COUNTRY_SEARCH_SET = 'REQUEST_GEO_COUNTRY_SEARCH_SET';
export const REQUEST_GEO_COUNTRY_SEARCH_CHANGE = 'REQUEST_GEO_COUNTRY_SEARCH_CHANGE';
export const REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST = 'REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST';
export const REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST_SUCCESS = 'REQUEST_GEO_COUNTRY_SEARCH_REQUEST_SUCCESS';
export const REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST_ERROR = 'REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST_ERROR';
export const REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST = 'REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST';
export const REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_SUCCESS';
export const REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST_ERROR = 'REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const REQUEST_GEO_COUNTRY_ADD_SET = 'REQUEST_GEO_COUNTRY_ADD_SET';
export const REQUEST_GEO_COUNTRY_ADD_MODAL_SET = 'REQUEST_GEO_COUNTRY_ADD_MODAL_SET';
export const REQUEST_GEO_COUNTRY_ADD = 'REQUEST_GEO_COUNTRY_ADD';
export const REQUEST_GEO_COUNTRY_ADD_SUCCESS = 'REQUEST_GEO_COUNTRY_ADD_SUCCESS';
export const REQUEST_GEO_COUNTRY_ADD_ERROR = 'REQUEST_GEO_COUNTRY_ADD_ERROR';
export const REQUEST_GEO_COUNTRY_ADD_CHANGE = 'REQUEST_GEO_COUNTRY_ADD_CHANGE';
export const REQUEST_GEO_COUNTRY_DETAIL = 'REQUEST_GEO_COUNTRY_DETAIL';
export const REQUEST_GEO_COUNTRY_DETAIL_SUCCESS = 'REQUEST_GEO_COUNTRY_DETAIL_SUCCESS';
export const REQUEST_GEO_COUNTRY_DETAIL_ERROR = 'REQUEST_GEO_COUNTRY_DETAIL_ERROR';
export const REQUEST_GEO_COUNTRY_VIEW_MODAL_SET = 'REQUEST_GEO_COUNTRY_VIEW_MODAL_SET';
export const REQUEST_GEO_COUNTRY_EDIT_MODAL_SET = 'REQUEST_GEO_COUNTRY_EDIT_MODAL_SET';
export const REQUEST_GEO_COUNTRY_EDIT_SET = 'REQUEST_GEO_COUNTRY_EDIT_SET';
export const REQUEST_GEO_COUNTRY_EDIT_CHANGE = 'REQUEST_GEO_COUNTRY_EDIT_CHANGE';
export const REQUEST_GEO_COUNTRY_UPDATE = 'REQUEST_GEO_COUNTRY_UPDATE';
export const REQUEST_GEO_COUNTRY_UPDATE_SUCCESS = 'REQUEST_GEO_COUNTRY_UPDATE_SUCCESS';
export const REQUEST_GEO_COUNTRY_UPDATE_ERROR = 'REQUEST_GEO_COUNTRY_UPDATE_ERROR';
export const REQUEST_GEO_COUNTRY_VIEW_TAB_SET = 'REQUEST_GEO_COUNTRY_VIEW_TAB_SET';

/* REQUEST_EXPERIENCE */
export const REQUEST_EXPERIENCE_LIST = 'REQUEST_EXPERIENCE_LIST';
export const REQUEST_EXPERIENCE_LIST_SUCCESS = 'REQUEST_EXPERIENCE_LIST_SUCCESS';
export const REQUEST_EXPERIENCE_LIST_ERROR = 'REQUEST_EXPERIENCE_LIST_ERROR';
export const REQUEST_EXPERIENCE_PAGE_SET = 'REQUEST_EXPERIENCE_PAGE_SET';
export const REQUEST_EXPERIENCE_SEARCH_SET = 'REQUEST_EXPERIENCE_SEARCH_SET';
export const REQUEST_EXPERIENCE_SEARCH_CHANGE = 'REQUEST_EXPERIENCE_SEARCH_CHANGE';
export const REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST = 'REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST';
export const REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST_SUCCESS = 'REQUEST_EXPERIENCE_SEARCH_REQUEST_SUCCESS';
export const REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST_ERROR = 'REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST_ERROR';
export const REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST = 'REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST';
export const REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_SUCCESS';
export const REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST_ERROR = 'REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const REQUEST_EXPERIENCE_ADD_SET = 'REQUEST_EXPERIENCE_ADD_SET';
export const REQUEST_EXPERIENCE_ADD_MODAL_SET = 'REQUEST_EXPERIENCE_ADD_MODAL_SET';
export const REQUEST_EXPERIENCE_ADD = 'REQUEST_EXPERIENCE_ADD';
export const REQUEST_EXPERIENCE_ADD_SUCCESS = 'REQUEST_EXPERIENCE_ADD_SUCCESS';
export const REQUEST_EXPERIENCE_ADD_ERROR = 'REQUEST_EXPERIENCE_ADD_ERROR';
export const REQUEST_EXPERIENCE_ADD_CHANGE = 'REQUEST_EXPERIENCE_ADD_CHANGE'; 
export const REQUEST_EXPERIENCE_DETAIL = 'REQUEST_EXPERIENCE_DETAIL';
export const REQUEST_EXPERIENCE_DETAIL_SUCCESS = 'REQUEST_EXPERIENCE_DETAIL_SUCCESS';
export const REQUEST_EXPERIENCE_DETAIL_ERROR = 'REQUEST_EXPERIENCE_DETAIL_ERROR';
export const REQUEST_EXPERIENCE_VIEW_MODAL_SET = 'REQUEST_EXPERIENCE_VIEW_MODAL_SET';
export const REQUEST_EXPERIENCE_EDIT_MODAL_SET = 'REQUEST_EXPERIENCE_EDIT_MODAL_SET';
export const REQUEST_EXPERIENCE_EDIT_SET = 'REQUEST_EXPERIENCE_EDIT_SET';
export const REQUEST_EXPERIENCE_EDIT_CHANGE = 'REQUEST_EXPERIENCE_EDIT_CHANGE';
export const REQUEST_EXPERIENCE_UPDATE = 'REQUEST_EXPERIENCE_UPDATE';
export const REQUEST_EXPERIENCE_UPDATE_SUCCESS = 'REQUEST_EXPERIENCE_UPDATE_SUCCESS';
export const REQUEST_EXPERIENCE_UPDATE_ERROR = 'REQUEST_EXPERIENCE_UPDATE_ERROR';
export const REQUEST_EXPERIENCE_VIEW_TAB_SET = 'REQUEST_EXPERIENCE_VIEW_TAB_SET';

/* REQUEST_USER_CHAT */
export const REQUEST_USER_CHAT_LIST = 'REQUEST_USER_CHAT_LIST';
export const REQUEST_USER_CHAT_LIST_SUCCESS = 'REQUEST_USER_CHAT_LIST_SUCCESS';
export const REQUEST_USER_CHAT_LIST_ERROR = 'REQUEST_USER_CHAT_LIST_ERROR';
export const REQUEST_USER_CHAT_ADD_MODAL_SET = 'REQUEST_USER_CHAT_ADD_MODAL_SET';
export const REQUEST_USER_CHAT_ADD_CHANGE = 'REQUEST_USER_CHAT_ADD_CHANGE';
export const REQUEST_USER_CHAT_ADD = 'REQUEST_USER_CHAT_ADD';
export const REQUEST_USER_CHAT_ADD_SUCCESS = 'REQUEST_USER_CHAT_ADD_SUCCESS';
export const REQUEST_USER_CHAT_ADD_ERROR = 'REQUEST_USER_CHAT_ADD_ERROR';

/* REQUEST_MESSAGE */
export const REQUEST_MESSAGE_LIST = 'REQUEST_MESSAGE_LIST';
export const REQUEST_MESSAGE_LIST_SUCCESS = 'REQUEST_MESSAGE_LIST_SUCCESS';
export const REQUEST_MESSAGE_LIST_ERROR = 'REQUEST_MESSAGE_LIST_ERROR';
export const REQUEST_MESSAGE_PAGE_SET = 'REQUEST_MESSAGE_PAGE_SET';
export const REQUEST_MESSAGE_SEARCH_SET = 'REQUEST_MESSAGE_SEARCH_SET';
export const REQUEST_MESSAGE_SEARCH_CHANGE = 'REQUEST_MESSAGE_SEARCH_CHANGE';
export const REQUEST_MESSAGE_SEARCH_REQUEST_LIST = 'REQUEST_MESSAGE_SEARCH_REQUEST_LIST';
export const REQUEST_MESSAGE_SEARCH_REQUEST_LIST_SUCCESS = 'REQUEST_MESSAGE_SEARCH_REQUEST_SUCCESS';
export const REQUEST_MESSAGE_SEARCH_REQUEST_LIST_ERROR = 'REQUEST_MESSAGE_SEARCH_REQUEST_LIST_ERROR';
export const REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST = 'REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST';
export const REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_SUCCESS';
export const REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST_ERROR = 'REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const REQUEST_MESSAGE_ADD_SET = 'REQUEST_MESSAGE_ADD_SET';
export const REQUEST_MESSAGE_ADD_MODAL_SET = 'REQUEST_MESSAGE_ADD_MODAL_SET';
export const REQUEST_MESSAGE_ADD = 'REQUEST_MESSAGE_ADD';
export const REQUEST_MESSAGE_ADD_SUCCESS = 'REQUEST_MESSAGE_ADD_SUCCESS';
export const REQUEST_MESSAGE_ADD_ERROR = 'REQUEST_MESSAGE_ADD_ERROR';
export const REQUEST_MESSAGE_ADD_CHANGE = 'REQUEST_MESSAGE_ADD_CHANGE'; 
export const REQUEST_MESSAGE_DETAIL = 'REQUEST_MESSAGE_DETAIL';
export const REQUEST_MESSAGE_DETAIL_SUCCESS = 'REQUEST_MESSAGE_DETAIL_SUCCESS';
export const REQUEST_MESSAGE_DETAIL_ERROR = 'REQUEST_MESSAGE_DETAIL_ERROR';
export const REQUEST_MESSAGE_VIEW_MODAL_SET = 'REQUEST_MESSAGE_VIEW_MODAL_SET';
export const REQUEST_MESSAGE_EDIT_MODAL_SET = 'REQUEST_MESSAGE_EDIT_MODAL_SET';
export const REQUEST_MESSAGE_EDIT_SET = 'REQUEST_MESSAGE_EDIT_SET';
export const REQUEST_MESSAGE_EDIT_CHANGE = 'REQUEST_MESSAGE_EDIT_CHANGE';
export const REQUEST_MESSAGE_UPDATE = 'REQUEST_MESSAGE_UPDATE';
export const REQUEST_MESSAGE_UPDATE_SUCCESS = 'REQUEST_MESSAGE_UPDATE_SUCCESS';
export const REQUEST_MESSAGE_UPDATE_ERROR = 'REQUEST_MESSAGE_UPDATE_ERROR';
export const REQUEST_MESSAGE_VIEW_TAB_SET = 'REQUEST_MESSAGE_VIEW_TAB_SET';

/* REQUEST_DETAIL_HEALTH_REGISTRY */
export const REQUEST_DETAIL_HEALTH_REGISTRY_LIST = 'REQUEST_DETAIL_HEALTH_REGISTRY_LIST';
export const REQUEST_DETAIL_HEALTH_REGISTRY_LIST_SUCCESS = 'REQUEST_DETAIL_HEALTH_REGISTRY_LIST_SUCCESS';
export const REQUEST_DETAIL_HEALTH_REGISTRY_LIST_ERROR = 'REQUEST_DETAIL_HEALTH_REGISTRY_LIST_ERROR';
export const REQUEST_DETAIL_HEALTH_REGISTRY_PAGE_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_PAGE_SET';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_SET';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_CHANGE = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_CHANGE';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_SUCCESS';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_ERROR = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_SUCCESS = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_SUCCESS';
export const REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_ERROR = 'REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_ERROR';
export const REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SET';
export const REQUEST_DETAIL_HEALTH_REGISTRY_ADD_MODAL_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_ADD_MODAL_SET';
export const REQUEST_DETAIL_HEALTH_REGISTRY_ADD = 'REQUEST_DETAIL_HEALTH_REGISTRY_ADD';
export const REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SUCCESS = 'REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SUCCESS';
export const REQUEST_DETAIL_HEALTH_REGISTRY_ADD_ERROR = 'REQUEST_DETAIL_HEALTH_REGISTRY_ADD_ERROR';
export const REQUEST_DETAIL_HEALTH_REGISTRY_ADD_CHANGE = 'REQUEST_DETAIL_HEALTH_REGISTRY_ADD_CHANGE';
export const REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL = 'REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL';
export const REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_SUCCESS = 'REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_SUCCESS';
export const REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_ERROR = 'REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_ERROR';
export const REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_MODAL_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_MODAL_SET';
export const REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_MODAL_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_MODAL_SET';
export const REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_SET';
export const REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_CHANGE = 'REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_CHANGE';
export const REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE = 'REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE';
export const REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_SUCCESS = 'REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_SUCCESS';
export const REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_ERROR = 'REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_ERROR';
export const REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_TAB_SET = 'REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_TAB_SET';

/* REQUEST_DETAIL_DELIVERY_TERM */
export const REQUEST_DETAIL_DELIVERY_TERM_LIST = 'REQUEST_DETAIL_DELIVERY_TERM_LIST';
export const REQUEST_DETAIL_DELIVERY_TERM_LIST_SUCCESS = 'REQUEST_DETAIL_DELIVERY_TERM_LIST_SUCCESS';
export const REQUEST_DETAIL_DELIVERY_TERM_LIST_ERROR = 'REQUEST_DETAIL_DELIVERY_TERM_LIST_ERROR';
export const REQUEST_DETAIL_DELIVERY_TERM_PAGE_SET = 'REQUEST_DETAIL_DELIVERY_TERM_PAGE_SET';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_SET = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_SET';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_CHANGE = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_CHANGE';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_SUCCESS = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_SUCCESS';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_ERROR = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_ERROR';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_SUCCESS = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_SUCCESS';
export const REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_ERROR = 'REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_ERROR';
export const REQUEST_DETAIL_DELIVERY_TERM_ADD_SET = 'REQUEST_DETAIL_DELIVERY_TERM_ADD_SET';
export const REQUEST_DETAIL_DELIVERY_TERM_ADD_MODAL_SET = 'REQUEST_DETAIL_DELIVERY_TERM_ADD_MODAL_SET';
export const REQUEST_DETAIL_DELIVERY_TERM_ADD = 'REQUEST_DETAIL_DELIVERY_TERM_ADD';
export const REQUEST_DETAIL_DELIVERY_TERM_ADD_SUCCESS = 'REQUEST_DETAIL_DELIVERY_TERM_ADD_SUCCESS';
export const REQUEST_DETAIL_DELIVERY_TERM_ADD_ERROR = 'REQUEST_DETAIL_DELIVERY_TERM_ADD_ERROR';
export const REQUEST_DETAIL_DELIVERY_TERM_ADD_CHANGE = 'REQUEST_DETAIL_DELIVERY_TERM_ADD_CHANGE';
export const REQUEST_DETAIL_DELIVERY_TERM_DETAIL = 'REQUEST_DETAIL_DELIVERY_TERM_DETAIL';
export const REQUEST_DETAIL_DELIVERY_TERM_DETAIL_SUCCESS = 'REQUEST_DETAIL_DELIVERY_TERM_DETAIL_SUCCESS';
export const REQUEST_DETAIL_DELIVERY_TERM_DETAIL_ERROR = 'REQUEST_DETAIL_DELIVERY_TERM_DETAIL_ERROR';
export const REQUEST_DETAIL_DELIVERY_TERM_VIEW_MODAL_SET = 'REQUEST_DETAIL_DELIVERY_TERM_VIEW_MODAL_SET';
export const REQUEST_DETAIL_DELIVERY_TERM_EDIT_MODAL_SET = 'REQUEST_DETAIL_DELIVERY_TERM_EDIT_MODAL_SET';
export const REQUEST_DETAIL_DELIVERY_TERM_EDIT_SET = 'REQUEST_DETAIL_DELIVERY_TERM_EDIT_SET';
export const REQUEST_DETAIL_DELIVERY_TERM_EDIT_CHANGE = 'REQUEST_DETAIL_DELIVERY_TERM_EDIT_CHANGE';
export const REQUEST_DETAIL_DELIVERY_TERM_UPDATE = 'REQUEST_DETAIL_DELIVERY_TERM_UPDATE';
export const REQUEST_DETAIL_DELIVERY_TERM_UPDATE_SUCCESS = 'REQUEST_DETAIL_DELIVERY_TERM_UPDATE_SUCCESS';
export const REQUEST_DETAIL_DELIVERY_TERM_UPDATE_ERROR = 'REQUEST_DETAIL_DELIVERY_TERM_UPDATE_ERROR';
export const REQUEST_DETAIL_DELIVERY_TERM_VIEW_TAB_SET = 'REQUEST_DETAIL_DELIVERY_TERM_VIEW_TAB_SET';

/* SUPPLIER_EMAIL */
export const SUPPLIER_EMAIL_LIST = 'SUPPLIER_EMAIL_LIST';
export const SUPPLIER_EMAIL_LIST_SUCCESS = 'SUPPLIER_EMAIL_LIST_SUCCESS';
export const SUPPLIER_EMAIL_LIST_ERROR = 'SUPPLIER_EMAIL_LIST_ERROR';
export const SUPPLIER_EMAIL_PAGE_SET = 'SUPPLIER_EMAIL_PAGE_SET';
export const SUPPLIER_EMAIL_SEARCH_SET = 'SUPPLIER_EMAIL_SEARCH_SET';
export const SUPPLIER_EMAIL_SEARCH_CHANGE = 'SUPPLIER_EMAIL_SEARCH_CHANGE';
export const SUPPLIER_EMAIL_SEARCH_REQUEST_LIST = 'SUPPLIER_EMAIL_SEARCH_REQUEST_LIST';
export const SUPPLIER_EMAIL_SEARCH_REQUEST_LIST_SUCCESS = 'SUPPLIER_EMAIL_SEARCH_REQUEST_SUCCESS';
export const SUPPLIER_EMAIL_SEARCH_REQUEST_LIST_ERROR = 'SUPPLIER_EMAIL_SEARCH_REQUEST_LIST_ERROR';
export const SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST = 'SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST';
export const SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_SUCCESS';
export const SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR = 'SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const SUPPLIER_EMAIL_ADD_SET = 'SUPPLIER_EMAIL_ADD_SET';
export const SUPPLIER_EMAIL_ADD_MODAL_SET = 'SUPPLIER_EMAIL_ADD_MODAL_SET';
export const SUPPLIER_EMAIL_ADD = 'SUPPLIER_EMAIL_ADD';
export const SUPPLIER_EMAIL_ADD_SUCCESS = 'SUPPLIER_EMAIL_ADD_SUCCESS';
export const SUPPLIER_EMAIL_ADD_ERROR = 'SUPPLIER_EMAIL_ADD_ERROR';
export const SUPPLIER_EMAIL_ADD_CHANGE = 'SUPPLIER_EMAIL_ADD_CHANGE';
export const SUPPLIER_EMAIL_DETAIL = 'SUPPLIER_EMAIL_DETAIL';
export const SUPPLIER_EMAIL_DETAIL_SUCCESS = 'SUPPLIER_EMAIL_DETAIL_SUCCESS';
export const SUPPLIER_EMAIL_DETAIL_ERROR = 'SUPPLIER_EMAIL_DETAIL_ERROR';
export const SUPPLIER_EMAIL_VIEW_MODAL_SET = 'SUPPLIER_EMAIL_VIEW_MODAL_SET';
export const SUPPLIER_EMAIL_EDIT_MODAL_SET = 'SUPPLIER_EMAIL_EDIT_MODAL_SET';
export const SUPPLIER_EMAIL_EDIT_SET = 'SUPPLIER_EMAIL_EDIT_SET';
export const SUPPLIER_EMAIL_EDIT_CHANGE = 'SUPPLIER_EMAIL_EDIT_CHANGE';
export const SUPPLIER_EMAIL_UPDATE = 'SUPPLIER_EMAIL_UPDATE';
export const SUPPLIER_EMAIL_UPDATE_SUCCESS = 'SUPPLIER_EMAIL_UPDATE_SUCCESS';
export const SUPPLIER_EMAIL_UPDATE_ERROR = 'SUPPLIER_EMAIL_UPDATE_ERROR';
export const SUPPLIER_EMAIL_VIEW_TAB_SET = 'SUPPLIER_EMAIL_VIEW_TAB_SET';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './process/actions';
export * from './request/actions';
export * from './request_detail/actions';
export * from './search/actions';
export * from './request_answer/actions';
export * from './upload/actions';
export * from './request_detail_file/actions';
export * from './request_geo_country/actions';
export * from './request_experience/actions';
export * from './request_user_chat/actions';
export * from './request_message/actions';
export * from './request_detail_health_registry/actions';
export * from './request_detail_delivery_term/actions';
export * from './supplier_email/actions';