import {
  PROCESS_LIST,
  PROCESS_LIST_SUCCESS,
  PROCESS_LIST_ERROR,
  PROCESS_SEARCH_SET,
  PROCESS_SEARCH_GEO_COUNTRY_LIST,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR,
  PROCESS_SEARCH_PROCESS_STATE_LIST,
  PROCESS_SEARCH_PROCESS_STATE_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_STATE_LIST_ERROR,
  PROCESS_SEARCH_PROCESS_TYPE_LIST,
  PROCESS_SEARCH_PROCESS_TYPE_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_TYPE_LIST_ERROR,
} from '../actions';

const INIT_STATE = {
  loading: true,
  list: {
    data:[],
  },
  search:{
    modal: false,
    init:true,
    data:{
      geo_country: false,
      process_state: false
    },
    geo_country:{
      loading: true,
      list:[],
      message: ''
    },
    process_state:{
      loading: true,
      list:[],
      message: ''
    },
    process_type:{
      loading: true,
      list:[],
      message: ''
    },
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROCESS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        }
      }
    case PROCESS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          flag: action.payload.flag
        }
      }
    case PROCESS_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
        error: true
      }
    case PROCESS_SEARCH_SET:
      return {
        ...state,
        search:{
          ...state.search,
          data: action.payload,
        }
      }
    case PROCESS_SEARCH_GEO_COUNTRY_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          geo_country:{
            ...state.search.geo_country,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          geo_country:{
            ...state.search.geo_country,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          geo_country:{
            ...state.search.geo_country,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_STATE_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          process_state:{
            ...state.search.process_state,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_STATE_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          process_state:{
            ...state.search.process_state,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_STATE_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          process_state:{
            ...state.search.process_state,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_TYPE_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          process_type:{
            ...state.search.process_type,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_TYPE_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          process_type:{
            ...state.search.process_type,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_TYPE_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          process_type:{
            ...state.search.process_type,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    default:
      return { ...state };
  }
};