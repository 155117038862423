import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_INFO,
  REQUEST_DETAIL_INFO_SUCCESS,
  REQUEST_DETAIL_INFO_ERROR,
  REQUEST_DETAIL_PAGE_SET,
  REQUEST_DETAIL_EDIT_MODAL_SET,
  REQUEST_DETAIL_EDIT_CHANGE,
  REQUEST_DETAIL_UPDATE,
  REQUEST_DETAIL_UPDATE_SUCCESS,
  REQUEST_DETAIL_UPDATE_ERROR
} from '../actions'; 

export const NAME = 'request_detail';

export const RequestDetailInfo = (data) => ({
  type: REQUEST_DETAIL_INFO,
  payload: data
});

export const RequestDetailInfoSuccess = (data) => ({
  type: REQUEST_DETAIL_INFO_SUCCESS,
  payload: data
});

export const RequestDetailInfoError = (error) => ({
  type: REQUEST_DETAIL_INFO_ERROR,
  payload: error
});

export const RequestDetailPageSet = (data) => ({
  type: REQUEST_DETAIL_PAGE_SET,
  payload: data
});

export const RequestDetailEditModalSet = (data) => ({
  type: REQUEST_DETAIL_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_EDIT_CHANGE,
  payload: data
});

export const RequestDetailUpdate = (data) => ({
  type: REQUEST_DETAIL_UPDATE,
  payload: data
});

export const RequestDetailUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailUpdateError = (error) => ({
  type: REQUEST_DETAIL_UPDATE_ERROR,
  payload: error
});

const request_detail = (state) => state[NAME];

export const RequestDetailSelector = createStructuredSelector({
  request_detail
})
