import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { getCurrentLanguage } from '../../helpers/Utils';
import { 
  SUPPLIER_EMAIL_LIST,
  SUPPLIER_EMAIL_SEARCH_REQUEST_LIST,
  SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST,
  SUPPLIER_EMAIL_ADD,
  SUPPLIER_EMAIL_DETAIL,
  SUPPLIER_EMAIL_UPDATE,
} from '../actions';
import {
  SupplierEmailListSuccess,
  SupplierEmailListError,
  SupplierEmailSearchRequestListSuccess,
  SupplierEmailSearchRequestListError,
  SupplierEmailSearchGeoCountryListSuccess,
  SupplierEmailSearchGeoCountryListError,
  SupplierEmailAddSuccess,
  SupplierEmailAddError,
  SupplierEmailDetailSuccess,
  SupplierEmailDetailError,
  SupplierEmailUpdateSuccess,
  SupplierEmailUpdateError
} from '../actions';
 
const locale = getCurrentLanguage();
export function* watchSupplierEmailList() {
  yield takeEvery(SUPPLIER_EMAIL_LIST, onSupplierEmailList);
}

export function* watchSupplierEmailSearchRequestList() {
  yield takeEvery(SUPPLIER_EMAIL_SEARCH_REQUEST_LIST, onSupplierEmailSearchRequestList);
}

export function* watchSupplierEmailSearchGeoCountryList() {
  yield takeEvery(SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST, onSupplierEmailSearchGeoCountryList);
}

export function* watchSupplierEmailAdd() {
  yield takeEvery(SUPPLIER_EMAIL_ADD, onSupplierEmailAdd);
}

export function* watchSupplierEmailDetail() {
  yield takeEvery(SUPPLIER_EMAIL_DETAIL, onSupplierEmailDetail);
}

export function* watchSupplierEmailUpdate() {
  yield takeEvery(SUPPLIER_EMAIL_UPDATE, onSupplierEmailUpdate);
}

const onSupplierEmailListAsync  = async (payload) => {
  const response = await fetch(servicePath + 'supplier_email/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onSupplierEmailUpdateAsync = async (data) => {
  const response = await fetch(servicePath + 'supplier_email/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onSupplierEmailSearchRequestListAsync = async (payload) => {
  const response = await fetch(servicePath + 'search/request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSupplierEmailSearchGeoCountryListAsync = async (payload) => {
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onSupplierEmailAddAsync = async (data) => {
  const response = await fetch(servicePath + 'supplier_email/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onSupplierEmailDetailAsync  = async (payload) => {
  const response = await fetch(servicePath + 'supplier_email/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onSupplierEmailList(data) {
  try {
    const response = yield call(onSupplierEmailListAsync, data.payload);
    yield put(SupplierEmailListSuccess(response.data));
  } catch (error) {
    yield put(SupplierEmailListError(error));
  }
}

function* onSupplierEmailSearchRequestList(data) {
  try {
    console.log('onSupplierEmailSearchRequestList',data.payload)
    const response = yield call(onSupplierEmailSearchRequestListAsync, data.payload);
    yield put(SupplierEmailSearchRequestListSuccess(response.data));
  } catch (error) {
    yield put(SupplierEmailSearchRequestListError(error));
  }
}

function* onSupplierEmailSearchGeoCountryList(data) {
  try {
    const response = yield call(onSupplierEmailSearchGeoCountryListAsync, data.payload);
    yield put(SupplierEmailSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(SupplierEmailSearchGeoCountryListError(error));
  }
}

function* onSupplierEmailAdd(data) {
  try {
      const response = yield call(onSupplierEmailAddAsync, data);

      if (response.data.status !== 'error') { 
          NotificationManager.success(locale==='es'?'Solicitud enviada':'Request sent', locale==='es'? 'Éxito':'Success', 3000, null, null, '');
      }
      if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(SupplierEmailAddSuccess(response.data));
  } catch (error) {
      yield put(SupplierEmailAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onSupplierEmailDetail(data) {
  try {
    const response = yield call(onSupplierEmailDetailAsync, data.payload);
    yield put(SupplierEmailDetailSuccess(response.data.data));
  } catch (error) {
    yield put(SupplierEmailDetailError(error));
  }
}

function* onSupplierEmailUpdate(data) {
  try {
      const response = yield call(onSupplierEmailUpdateAsync, data.payload);
      if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(SupplierEmailUpdateSuccess(response.data));
  } catch (error) {
      yield put(SupplierEmailUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchSupplierEmailList),
    fork(watchSupplierEmailSearchRequestList),
    fork(watchSupplierEmailSearchGeoCountryList),
    fork(watchSupplierEmailAdd),
    fork(watchSupplierEmailDetail),
    fork(watchSupplierEmailUpdate),
  ]);
} 