import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_GEO_COUNTRY_LIST,
  REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST,
  REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_GEO_COUNTRY_ADD,
  REQUEST_GEO_COUNTRY_DETAIL,
  REQUEST_GEO_COUNTRY_UPDATE,
} from '../actions';
import {
  RequestGeoCountryListSuccess,
  RequestGeoCountryListError,
  RequestGeoCountrySearchRequestListSuccess,
  RequestGeoCountrySearchRequestListError,
  RequestGeoCountrySearchGeoCountryListSuccess,
  RequestGeoCountrySearchGeoCountryListError,
  RequestGeoCountryAddSuccess,
  RequestGeoCountryAddError,
  RequestGeoCountryDetailSuccess,
  RequestGeoCountryDetailError,
  RequestGeoCountryUpdateSuccess,
  RequestGeoCountryUpdateError
} from '../actions';
 
export function* watchRequestGeoCountryList() {
  yield takeEvery(REQUEST_GEO_COUNTRY_LIST, onRequestGeoCountryList);
}

export function* watchRequestGeoCountrySearchRequestList() {
  yield takeEvery(REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST, onRequestGeoCountrySearchRequestList);
}

export function* watchRequestGeoCountrySearchGeoCountryList() {
  yield takeEvery(REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST, onRequestGeoCountrySearchGeoCountryList);
}

export function* watchRequestGeoCountryAdd() {
  yield takeEvery(REQUEST_GEO_COUNTRY_ADD, onRequestGeoCountryAdd);
}

export function* watchRequestGeoCountryDetail() {
  yield takeEvery(REQUEST_GEO_COUNTRY_DETAIL, onRequestGeoCountryDetail);
}

export function* watchRequestGeoCountryUpdate() {
  yield takeEvery(REQUEST_GEO_COUNTRY_UPDATE, onRequestGeoCountryUpdate);
}

const onRequestGeoCountryListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_geo_country/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestGeoCountryUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_geo_country/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestGeoCountrySearchRequestListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestGeoCountrySearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestGeoCountryAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_geo_country/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestGeoCountryDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_geo_country/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestGeoCountryList(data) {
  try {
    const response = yield call(onRequestGeoCountryListAsync, data.payload);
    yield put(RequestGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestGeoCountryListError(error));
  }
}

function* onRequestGeoCountrySearchRequestList(data) {
  try {
    console.log('onRequestGeoCountrySearchRequestList',data.payload)
    const response = yield call(onRequestGeoCountrySearchRequestListAsync, data.payload);
    yield put(RequestGeoCountrySearchRequestListSuccess(response.data));
  } catch (error) {
    yield put(RequestGeoCountrySearchRequestListError(error));
  }
}

function* onRequestGeoCountrySearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestGeoCountrySearchGeoCountryListAsync, data.payload);
    yield put(RequestGeoCountrySearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestGeoCountrySearchGeoCountryListError(error));
  }
}

function* onRequestGeoCountryAdd(data) {
  try {
      const response = yield call(onRequestGeoCountryAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestGeoCountryAddSuccess(response.data));
  } catch (error) {
      yield put(RequestGeoCountryAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestGeoCountryDetail(data) {
  try {
    const response = yield call(onRequestGeoCountryDetailAsync, data.payload);
    yield put(RequestGeoCountryDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestGeoCountryDetailError(error));
  }
}

function* onRequestGeoCountryUpdate(data) {
  try {
      const response = yield call(onRequestGeoCountryUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestGeoCountryUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestGeoCountryUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestGeoCountryList),
    fork(watchRequestGeoCountrySearchRequestList),
    fork(watchRequestGeoCountrySearchGeoCountryList),
    fork(watchRequestGeoCountryAdd),
    fork(watchRequestGeoCountryDetail),
    fork(watchRequestGeoCountryUpdate),
  ]);
} 