export const defaultMenuType = 'menu-hidden';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [{ id: 'es', name: 'Español', direction: 'ltr' }, { id: 'en', name: 'English', direction: 'ltr' }];

export const adminRoot = '/app';
export const userRoot = '/user';
export const verifiedRooty = 'https://xmarket-dev.web.app/user/verified/';
export const verifiedRoot = 'https://mercado.unops.org/user/verified/';
export const verifiedRootX = 'http://localhost:3000/user/verified/';

export const searchPath = `${adminRoot}/#`;
export const servicePathx = 'http://localhost:5001/unops-pharmamxapp-dev/us-central1/xmarket_dev/';
export const servicePath = 'https://us-central1-unops-pharmamxapp-dev.cloudfunctions.net/xmarket_dev/';
export const currentUser = {
  id: 1,
  title: 'No definido',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
}

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueyale';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = ['blueyale'];