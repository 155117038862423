import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';

import { firebaseConfig } from '../constants/defaultConfig';

firebase.initializeApp(firebaseConfig);

const authFB = firebase.auth();
const auth = firebase.auth();
const database = firebase.firestore();
const analytics = firebase.analytics();
const db = firebase.firestore();
const storage = firebase.storage();

export { auth, authFB, database, analytics, db, storage, firebase };
