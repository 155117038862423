import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import {
  REQUEST_DETAIL_INFO,
  REQUEST_DETAIL_UPDATE
} from '../actions';

import { 
  RequestDetailInfoSuccess,
  RequestDetailInfoError,
  RequestDetailUpdateSuccess,
  RequestDetailUpdateError
} from '../actions'; 

export function* watchRequestDetailInfo() {
  yield takeEvery(REQUEST_DETAIL_INFO, onRequestDetailInfo);
}

export function* watchRequestDetailUpdate() {
  yield takeEvery(REQUEST_DETAIL_UPDATE, onRequestDetailUpdate);
}

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestDetailInfoAsync  = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail/info', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data.payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail/update', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { message: true, data: response };
};

function* onRequestDetailInfo(data) {
  try {
    const response = yield call(onRequestDetailInfoAsync, data);
    yield put(RequestDetailInfoSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailInfoError(error));
  }
}

function* onRequestDetailUpdate(data) {
  try {
    const response = yield call(onRequestDetailUpdateAsync, data.payload);
    if (response.data.status === true) {
      NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
    } else if (response.data.status === 'error') {
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
    }
    yield put(RequestDetailUpdateSuccess(response.data));
  } catch (error) {
    alert(error)
    yield put(RequestDetailUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailInfo),
    fork(watchRequestDetailUpdate)
  ]);
}