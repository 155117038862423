import { createStructuredSelector } from 'reselect'

import {
  REQUEST_ANSWER_INFO,
  REQUEST_ANSWER_INFO_SUCCESS,
  REQUEST_ANSWER_INFO_ERROR,
  REQUEST_ANSWER_PAGE_SET,
  REQUEST_ANSWER_EDIT_MODAL_SET,
  REQUEST_ANSWER_EDIT_CHANGE,
  REQUEST_ANSWER_UPDATE,
  REQUEST_ANSWER_UPDATE_SUCCESS,
  REQUEST_ANSWER_UPDATE_ERROR
} from '../actions';

export const NAME = 'request_answer';

export const RequestAnswerInfo = (data) => ({
  type: REQUEST_ANSWER_INFO,
  payload: data
});

export const RequestAnswerInfoSuccess = (data) => ({
  type: REQUEST_ANSWER_INFO_SUCCESS,
  payload: data
});

export const RequestAnswerInfoError = (error) => ({
  type: REQUEST_ANSWER_INFO_ERROR,
  payload: error
});

export const RequestAnswerPageSet = (data) => ({
  type: REQUEST_ANSWER_PAGE_SET,
  payload: data
});

export const RequestAnswerEditModalSet = (data) => ({
  type: REQUEST_ANSWER_EDIT_MODAL_SET,
  payload: data
});

export const RequestAnswerEditChangeSet = (data) => ({
  type: REQUEST_ANSWER_EDIT_CHANGE,
  payload: data
});

export const RequestAnswerUpdate = (data) => ({
  type: REQUEST_ANSWER_UPDATE,
  payload: data
});

export const RequestAnswerUpdateSuccess = (message) => ({
  type: REQUEST_ANSWER_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestAnswerUpdateError = (error) => ({
  type: REQUEST_ANSWER_UPDATE_ERROR,
  payload: error
});

const request_answer = (state) => state[NAME];

export const RequestAnswerSelector = createStructuredSelector({
  request_answer
})
