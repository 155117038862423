import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import moment from 'moment';
import { storage, auth } from "../../helpers/Firebase";
import { UPLOAD_SET } from "../actions";
import { uploadSetSuccess, uploadSetError } from "./actions";

const onUploadAsync = async (file) => {
  var user = await auth.currentUser;
  var storageRef = storage.ref()
  return await new Promise((success, reject) => {
    let now = moment();
    let filename = `${now.format("x")}_${Math.ceil(Math.random() * (1000 - 0) + 0)}.${file.name.substr(file.name.lastIndexOf('.') + 1)}`;
    var uploadTask = storageRef.child(user.uid+'/xmarket/' + filename).put(file);
    uploadTask.on('state_changed',
    function(snapshot) {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
    }, 
    function(error) {
      reject({ name: filename, original: file.name, message: error.code, url: '',  type: file.type, size: file.size, extension: file.name.substr(file.name.lastIndexOf('.') + 1), path: user.uid+'/delivery_file/' })
    }, 
    function() {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) { 
        success({ message: 'success', name: filename, original: file.name, url: downloadURL, type: file.type, size: file.size, extension: file.name.substr(file.name.lastIndexOf('.') + 1), path: user.uid+'/delivery_file/'});
      });
    });
  });
};

function* onUpload({ payload }) {
  const { file } = payload;
  try {
    const response = yield call(onUploadAsync, file);
    if(response.message ==='success'){
      yield put(uploadSetSuccess(response));
    }
    else{
      yield put(uploadSetError(response));
    }
  } catch (error) {
    yield put(uploadSetError({ name: '', original: file.name, message: error, url: '',  type: file.type, size: file.size, extension: file.name.substr(file.name.lastIndexOf('.') + 1), path:''}))
  }
}

export function* watchUpload() {
  yield takeEvery(UPLOAD_SET, onUpload);
}

export default function* rootSaga() {
  yield all([fork(watchUpload)]);
}
