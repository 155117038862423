import { createStructuredSelector } from 'reselect'

import {
  REQUEST_USER_CHAT_LIST,
  REQUEST_USER_CHAT_LIST_SUCCESS,
  REQUEST_USER_CHAT_LIST_ERROR,
  REQUEST_USER_CHAT_ADD_MODAL_SET,
  REQUEST_USER_CHAT_ADD_CHANGE,
  REQUEST_USER_CHAT_ADD,
  REQUEST_USER_CHAT_ADD_SUCCESS,
  REQUEST_USER_CHAT_ADD_ERROR
} from '../actions';

export const NAME = 'request_user_chat';

export const RequestUserChatList = (data) => ({
  type: REQUEST_USER_CHAT_LIST,
  payload: data
});

export const RequestUserChatListSuccess = (data) => ({
  type: REQUEST_USER_CHAT_LIST_SUCCESS,
  payload: data 
});

export const RequestUserChatListError = (error) => ({
  type: REQUEST_USER_CHAT_LIST_ERROR,
  payload: error
});


export const RequestUserChatAddModalSet = (data) => ({
  type: REQUEST_USER_CHAT_ADD_MODAL_SET,
  payload: data
});

export const RequestUserChatAddChangeSet = (data) => ({
  type: REQUEST_USER_CHAT_ADD_CHANGE,
  payload: data
});

export const RequestUserChatAdd = (data) => ({
  type: REQUEST_USER_CHAT_ADD,
  payload: data
});

export const RequestUserChatAddSuccess = (message) => ({
  type: REQUEST_USER_CHAT_ADD_SUCCESS,
  payload: message.message
});

export const RequestUserChatAddError = (error) => ({
  type: REQUEST_USER_CHAT_ADD_ERROR,
  payload: error
});

const request_user_chat = (state) => state[NAME];

export const RequestUserChatSelector = createStructuredSelector({
  request_user_chat
})
