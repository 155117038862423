import {
  REQUEST_USER_CHAT_LIST,
  REQUEST_USER_CHAT_LIST_SUCCESS,
  REQUEST_USER_CHAT_LIST_ERROR,
  REQUEST_USER_CHAT_ADD_MODAL_SET,
  REQUEST_USER_CHAT_ADD_CHANGE,
  REQUEST_USER_CHAT_ADD,
  REQUEST_USER_CHAT_ADD_SUCCESS,
  REQUEST_USER_CHAT_ADD_ERROR 
} from '../actions';

const INIT_STATE = {
  loading: true,
  list: {
    data:[],
  },
  search:{
    modal: false,
    init:true,
    data:[],
  },
  add: {
    loading: true,
    data: {
      new:[],
      old:[]
    },
    modal: false,
    update: {
      loading: false,
      response: '',
      message: ''
    }
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_USER_CHAT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        }
      }
    case REQUEST_USER_CHAT_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data
        }
      }
    case REQUEST_USER_CHAT_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
        error: true
      }
    case REQUEST_USER_CHAT_ADD_MODAL_SET:
      return {
        ...state,
        add: {
          ...state.add,
          modal: action.payload
        }
      }
    case REQUEST_USER_CHAT_ADD_CHANGE:
      return {
        ...state,
        add: {
          ...state.add,
          data: {
            ...state.add.data,
            new: {
              ...state.add.data.new,
              ...action.payload
            }
          }
        }
      }
    case REQUEST_USER_CHAT_ADD:
      return {
        ...state,
        add: {
          ...state.add,
          data: {
            ...state.add.data,
            ...action.payload,
          },
          update: {
            ...state.update,
            loading: true,
            response: '',
            message: ''
          }
        }
      }
    case REQUEST_USER_CHAT_ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          modal:false,
          data: {
            ...state.add.data,
            new: {
              ...state.add.data.new
            },
            old: {
              ...state.add.data.new
            }
          },
          update: {
            ...state.update,
            loading: false,
            response: 'success',
            message: action.payload
          }
        }
      }
    case REQUEST_USER_CHAT_ADD_ERROR:
      return {
        ...state,
        add: {
          ...state.add,
          update: {
            ...state.update,
            loading: false,
            response: 'error',
            message: action.payload
          }
        }
      }
    default:
      return { ...state };
  }
};