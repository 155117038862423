/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import process from './process/saga';
import request from './request/saga';
import request_detail from './request_detail/saga';
import search from './search/saga';
import request_answer from './request_answer/saga';
import upload from './upload/saga';
import request_detail_file from './request_detail_file/saga';
import request_geo_country from './request_geo_country/saga';
import request_experience from './request_experience/saga';
import request_user_chat from './request_user_chat/saga';
import request_message from './request_message/saga';
import request_detail_health_registry from './request_detail_health_registry/saga';
import request_detail_delivery_term from './request_detail_delivery_term/saga';
import supplier_email from './supplier_email/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    process(),
    request(),
    request_detail(),
    search(),
    request_answer(),
    upload(),
    request_detail_file(),
    request_geo_country(),
    request_experience(),
    request_user_chat(),
    request_message(),
    request_detail_health_registry(),
    request_detail_delivery_term(),
    supplier_email()
  ]);
}
