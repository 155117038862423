import {
  REQUEST_DETAIL_INFO,
  REQUEST_DETAIL_INFO_SUCCESS,
  REQUEST_DETAIL_INFO_ERROR,
  REQUEST_DETAIL_PAGE_SET,
  REQUEST_DETAIL_EDIT_MODAL_SET,
  REQUEST_DETAIL_EDIT_CHANGE,
  REQUEST_DETAIL_UPDATE,
  REQUEST_DETAIL_UPDATE_SUCCESS, 
  REQUEST_DETAIL_UPDATE_ERROR 
} from '../actions';

const INIT_STATE = {
  loading: true,
  list: {
    data:[],
    page:{
      current: 1,
      rows: 50
    }
  },
  info: {
    data: {
      confirm:'No',
      edit:'Si'
    },
    loading: true,
  },
  edit: {
    loading: true,
    data: {
      new:[],
      old:[]
    },
    modal: false,
    update: {
      loading: false,
      response: '',
      message: ''
    }
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_DETAIL_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        }
      }
    case REQUEST_DETAIL_INFO_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
          data: action.payload
        },
        edit: {
          ...state.edit,
          loading: false,
          data: {
            new: action.payload ,
            old: action.payload
          }
        },
      }
    case REQUEST_DETAIL_INFO_ERROR:
      return {
        ...state,
        info: {
          ...state.info,
          loading: false
        }
      }
    case REQUEST_DETAIL_PAGE_SET:
      return {
        ...state,
        list: {
          ...state.list,
          page: {
            ...state.list.page,
            ... action.payload,
          }
        }
      }
    case REQUEST_DETAIL_EDIT_MODAL_SET:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal: action.payload
        }
      }
    case REQUEST_DETAIL_EDIT_CHANGE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new,
              ...action.payload
            }
          }
        }
      }
    case REQUEST_DETAIL_UPDATE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            ...action.payload,
          },
          update: {
            ...state.update,
            loading: true,
            response: '',
            message: ''
          }
        }
      }
    case REQUEST_DETAIL_UPDATE_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal:false,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new
            },
            old: {
              ...state.edit.data.new
            }
          },
          update: {
            ...state.update,
            loading: false,
            response: 'success',
            message: action.payload
          }
        }
      }
    case REQUEST_DETAIL_UPDATE_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          update: {
            ...state.update,
            loading: false,
            response: 'error',
            message: action.payload
          }
        }
      }
    default:
      return { ...state };
  }
};