import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB, auth } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGIN_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  loginPasswordSuccess,
  loginPasswordError,
} from './actions';

import { adminRoot, userRoot, verifiedRoot } from '../../constants/defaultValues';

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, sendSignInLinkToEmail);
}


export function* watchLoginPassword() {
  yield takeEvery(LOGIN_PASSWORD, loginWithEmailPassword);
}

const sendSignInLinkToEmailAsync = async (email) =>{
  var actionCodeSettings = {
    url: verifiedRoot,
    handleCodeInApp: true,
  };
  await authFB
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then((user) => user )
    .catch((error) => error);
  }
  

const loginWithEmailPasswordAsync = async (email, password) =>
  await authFB
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    console.log('loginWithEmailPassword')
    if (!loginUser.message) {
      const item = { uid: loginUser.user.uid };
      yield call( displayNameAsync, {email:email});
      yield put(loginPasswordSuccess(item));
      window.location.href = adminRoot;
    } else {
      yield put(loginPasswordError(loginUser.message));
    }
  } catch (error) {
    yield put(loginPasswordError(error));
  }
}

function* sendSignInLinkToEmail({ payload }) {
  const { email } = payload.user;
  try {
    const loginUser = yield call(sendSignInLinkToEmailAsync, email);
    window.localStorage.setItem('emailForSignIn', email)
    yield put(loginUserSuccess({message:'Hemos enviado un vinculo de acceso a tu correo electrónico'}));
    NotificationManager.success('Hemos enviado un vinculo de acceso a tu correo electrónico', 'Exito', 3000, null, null, '');
    setTimeout(() => {
      window.location.href = `${userRoot}/unverified`
    }, 1500);
  } catch (error) {
    yield put(loginUserError(error));
    //NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, signInLinkToEmail);
}

/*export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}*/
const registerWithEmailPasswordAsync = async (email, password) =>
  await authFB
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

const signInLinkToEmailAsync = async (email, href) =>{
  await authFB
  .signInWithEmailLink(email, href) 
  .then((user) => user)
  .catch((error) => error);
}
  
const registerSQLAsync = async (data) => {
  console.log('authFB.currentUser', authFB.currentUser)
  await authFB.currentUser.updateProfile({ displayName: data.email })
  
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'register', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { message: true, data: response };
};
const displayNameAsync = async (data) => {
  await authFB.currentUser.updateProfile({ displayName: data.email })
  return { message: true, data: [] };
};
function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  try {
    const registerUser = yield call( registerWithEmailPasswordAsync, email, password );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...payload.user};
      yield call( registerSQLAsync, item);
      yield put(registerUserSuccess(item));
      window.location.href = adminRoot;
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

function* signInLinkToEmail({ payload }) {
  const { email, link } = payload.user;
  try {
    const registerUser = yield call( signInLinkToEmailAsync, email, link );
    yield call( registerSQLAsync, {email:email});
    
    yield put(registerUserSuccess({email:email}));
    window.location.href = adminRoot
  } catch (error) {
    yield put(registerUserError(error));
    window.location.href = adminRoot
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await authFB
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await authFB
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await authFB
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginPassword),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
