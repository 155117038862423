import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { 
  PROCESS_LIST,
  PROCESS_SEARCH_GEO_COUNTRY_LIST,
  PROCESS_SEARCH_PROCESS_STATE_LIST,
  PROCESS_SEARCH_PROCESS_TYPE_LIST,
} from '../actions';

import {
  ProcessListSuccess,
  ProcessListError,
  ProcessSearchGeoCountryListSuccess,
  ProcessSearchGeoCountryListError,
  ProcessSearchProcessStateListSuccess,
  ProcessSearchProcessStateListError,
  ProcessSearchProcessTypeListSuccess,
  ProcessSearchProcessTypeListError
} from '../actions';

export function* watchProcessList() {
  yield takeEvery(PROCESS_LIST, onProcessList);
}

export function* watchProcessSearchGeoCountryList() {
  yield takeEvery(PROCESS_SEARCH_GEO_COUNTRY_LIST, onProcessSearchGeoCountryList);
}

export function* watchProcessSearchProcessStateList() {
  yield takeEvery(PROCESS_SEARCH_PROCESS_STATE_LIST, onProcessSearchProcessStateList);
}

export function* watchProcessSearchProcessTypeList() {
  yield takeEvery(PROCESS_SEARCH_PROCESS_TYPE_LIST, onProcessSearchProcessTypeList);
}

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onProcessListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onProcessSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country_project', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessSearchProcessStateListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/process_state', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessSearchProcessTypeListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request_process_type', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

function* onProcessList(data) {
  try {
    const response = yield call(onProcessListAsync, data.payload);
    yield put(ProcessListSuccess(response.data));
  } catch (error) {
    yield put(ProcessListError(error));
  }
}

function* onProcessSearchGeoCountryList(data) {
  try {
    const response = yield call(onProcessSearchGeoCountryListAsync, data.payload);
    yield put(ProcessSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(ProcessSearchGeoCountryListError(error));
  }
}

function* onProcessSearchProcessStateList(data) {
  try {
    const response = yield call(onProcessSearchProcessStateListAsync, data.payload);
    yield put(ProcessSearchProcessStateListSuccess(response.data));
  } catch (error) {
    yield put(ProcessSearchProcessStateListError(error));
  }
}

function* onProcessSearchProcessTypeList(data) {
  try {
    const response = yield call(onProcessSearchProcessTypeListAsync, data.payload);
    yield put(ProcessSearchProcessTypeListSuccess(response.data));
  } catch (error) {
    yield put(ProcessSearchProcessTypeListError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchProcessList),
    fork(watchProcessSearchGeoCountryList),
    fork(watchProcessSearchProcessStateList),
    fork(watchProcessSearchProcessTypeList)
  ]);
}