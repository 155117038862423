import { createStructuredSelector } from 'reselect'

import {
  REQUEST_EXPERIENCE_LIST,
  REQUEST_EXPERIENCE_LIST_SUCCESS,
  REQUEST_EXPERIENCE_LIST_ERROR,
  REQUEST_EXPERIENCE_PAGE_SET,
  REQUEST_EXPERIENCE_SEARCH_SET,
  REQUEST_EXPERIENCE_SEARCH_CHANGE,
  REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST,
  REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST_SUCCESS,
  REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST_ERROR,
  REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_EXPERIENCE_ADD_SET,
  REQUEST_EXPERIENCE_ADD_MODAL_SET,
  REQUEST_EXPERIENCE_ADD,
  REQUEST_EXPERIENCE_ADD_SUCCESS,
  REQUEST_EXPERIENCE_ADD_ERROR,
  REQUEST_EXPERIENCE_ADD_CHANGE,
  REQUEST_EXPERIENCE_DETAIL,
  REQUEST_EXPERIENCE_DETAIL_SUCCESS,
  REQUEST_EXPERIENCE_DETAIL_ERROR,
  REQUEST_EXPERIENCE_VIEW_MODAL_SET,
  REQUEST_EXPERIENCE_EDIT_CHANGE,
  REQUEST_EXPERIENCE_EDIT_MODAL_SET,
  REQUEST_EXPERIENCE_UPDATE,
  REQUEST_EXPERIENCE_UPDATE_SUCCESS,
  REQUEST_EXPERIENCE_UPDATE_ERROR,
  REQUEST_EXPERIENCE_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_experience';

export const RequestExperienceList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_EXPERIENCE_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestExperienceListSuccess = (data) => ({
  type: REQUEST_EXPERIENCE_LIST_SUCCESS,
  payload: data
});

export const RequestExperienceListError = (error) => ({
  type: REQUEST_EXPERIENCE_LIST_ERROR,
  payload: error
});

export const RequestExperiencePageSet = (data) => ({
  type: REQUEST_EXPERIENCE_PAGE_SET,
  payload: data
});

export const RequestExperienceSearchSet = (data) => ({
  type: REQUEST_EXPERIENCE_SEARCH_SET,
  payload: data
});

export const RequestExperienceSearchChangeSet = (data) => ({
  type: REQUEST_EXPERIENCE_SEARCH_CHANGE,
  payload: data
});

export const RequestExperienceSearchRequestList = (data) => ({
  type: REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST,
  payload: data
});

export const RequestExperienceSearchRequestListSuccess = (message) => ({
  type: REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const RequestExperienceSearchRequestListError = (error) => ({
  type: REQUEST_EXPERIENCE_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const RequestExperienceSearchGeoCountryList = (data) => ({
  type: REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestExperienceSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestExperienceSearchGeoCountryListError = (error) => ({
  type: REQUEST_EXPERIENCE_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const RequestExperienceAddChangeSet = (data) => ({
  type: REQUEST_EXPERIENCE_ADD_CHANGE,
  payload: data
});

export const RequestExperienceAddSet = (data) => ({
  type: REQUEST_EXPERIENCE_ADD_SET,
  payload: data
});

export const RequestExperienceAddModalSet = (data) => ({
  type: REQUEST_EXPERIENCE_ADD_MODAL_SET,
  payload: data
});

export const RequestExperienceAdd = (data) => ({
  type: REQUEST_EXPERIENCE_ADD,
  payload: data
});

export const RequestExperienceAddSuccess = (message) => ({
  type: REQUEST_EXPERIENCE_ADD_SUCCESS,
  payload: message
});

export const RequestExperienceAddError = (error) => ({
  type: REQUEST_EXPERIENCE_ADD_ERROR,
  payload: error
});

export const RequestExperienceDetail = (data) => ({
  type: REQUEST_EXPERIENCE_DETAIL,
  payload: data
});

export const RequestExperienceDetailSuccess = (data) => ({
  type: REQUEST_EXPERIENCE_DETAIL_SUCCESS,
  payload: data
});

export const RequestExperienceDetailError = (error) => ({
  type: REQUEST_EXPERIENCE_DETAIL_ERROR,
  payload: error
});

export const RequestExperienceViewModalSet = (data) => ({
  type: REQUEST_EXPERIENCE_VIEW_MODAL_SET,
  payload: data
});

export const RequestExperienceEditModalSet = (data) => ({
  type: REQUEST_EXPERIENCE_EDIT_MODAL_SET,
  payload: data
});

export const RequestExperienceEditChangeSet = (data) => ({
  type: REQUEST_EXPERIENCE_EDIT_CHANGE,
  payload: data
});

export const RequestExperienceUpdate = (data) => ({
  type: REQUEST_EXPERIENCE_UPDATE,
  payload: data
});

export const RequestExperienceUpdateSuccess = (message) => ({
  type: REQUEST_EXPERIENCE_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestExperienceUpdateError = (error) => ({
  type: REQUEST_EXPERIENCE_UPDATE_ERROR,
  payload: error
});

export const RequestExperienceViewTabSet = (data) => ({
  type: REQUEST_EXPERIENCE_VIEW_TAB_SET,
  payload: data
});

const requestExperience = (state) => state[NAME];

export const RequestExperienceSelector = createStructuredSelector({
  requestExperience
})
