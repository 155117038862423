import {
  UPLOAD_SET,
  UPLOAD_SET_SUCCESS,
  UPLOAD_SET_ERROR,
  UPLOAD_RESET
} from "../actions";

const INIT_STATE = {
  file: []
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPLOAD_SET:
      return { ...state };
    case UPLOAD_SET_SUCCESS:
      return {
        ...state,
        file: [...state.file, action.payload]
      };
    case UPLOAD_SET_ERROR:
      return { 
        ...state,
        file: [...state.file, action.payload]
      };
    case UPLOAD_RESET:
      return {
        ...state,
        file: []
      };
    default:
      return { ...state };
  }
};
