import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import {
  REQUEST_DETAIL_FILE_INFO,
  REQUEST_DETAIL_FILE_UPDATE
} from '../actions';

import { 
  RequestDetailFileInfoSuccess,
  RequestDetailFileInfoError,
  RequestDetailFileUpdateSuccess,
  RequestDetailFileUpdateError
} from '../actions'; 

export function* watchRequestDetailFileInfo() {
  yield takeEvery(REQUEST_DETAIL_FILE_INFO, onRequestDetailFileInfo);
}

export function* watchRequestDetailFileUpdate() {
  yield takeEvery(REQUEST_DETAIL_FILE_UPDATE, onRequestDetailFileUpdate);
}

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestDetailFileInfoAsync  = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_file/info', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data.payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailFileUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_file/update', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { message: true, data: response };
};

function* onRequestDetailFileInfo(data) {
  try {
    const response = yield call(onRequestDetailFileInfoAsync, data);
    yield put(RequestDetailFileInfoSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailFileInfoError(error));
  }
}

function* onRequestDetailFileUpdate(data) {
  try {
    const response = yield call(onRequestDetailFileUpdateAsync, data.payload);
    if (response.data.status === true) {
      NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
    } else if (response.data.status === 'error') {
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
    }
    yield put(RequestDetailFileUpdateSuccess(response.data));
  } catch (error) {
    alert(error)
    yield put(RequestDetailFileUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailFileInfo),
    fork(watchRequestDetailFileUpdate)
  ]);
}