import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import {
  REQUEST_ANSWER_INFO,
  REQUEST_ANSWER_UPDATE
} from '../actions';

import {
  RequestAnswerInfoSuccess,
  RequestAnswerInfoError,
  RequestAnswerUpdateSuccess,
  RequestAnswerUpdateError
} from '../actions';

export function* watchRequestAnswerInfo() {
  yield takeEvery(REQUEST_ANSWER_INFO, onRequestAnswerInfo);
}

export function* watchRequestAnswerUpdate() {
  yield takeEvery(REQUEST_ANSWER_UPDATE, onRequestAnswerUpdate);
}

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestAnswerInfoAsync  = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_answer/info', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data.payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestAnswerUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_answer/update', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { message: true, data: response };
};

function* onRequestAnswerInfo(data) {
  try {
    const response = yield call(onRequestAnswerInfoAsync, data);
    yield put(RequestAnswerInfoSuccess(response.data.data));
  } catch (error) {
    yield put(RequestAnswerInfoError(error));
  }
}

function* onRequestAnswerUpdate(data) {
  try {
    const response = yield call(onRequestAnswerUpdateAsync, data.payload);
    if (response.data.status === true) {
      NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
    } else if (response.data.status === 'error') {
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
    }
    yield put(RequestAnswerUpdateSuccess(response.data));
  } catch (error) {
    alert(error)
    yield put(RequestAnswerUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestAnswerInfo),
    fork(watchRequestAnswerUpdate)
  ]);
}