import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { 
  REQUEST_USER_CHAT_LIST,
  REQUEST_USER_CHAT_ADD
} from '../actions';

import {
  RequestUserChatListSuccess,
  RequestUserChatListError,
  RequestUserChatAddSuccess,
  RequestUserChatAddError
} from '../actions'; 

export function* watchRequestUserChatList() {
  yield takeEvery(REQUEST_USER_CHAT_LIST, onRequestUserChatList);
}

export function* watchRequestUserChatAdd() {
  yield takeEvery(REQUEST_USER_CHAT_ADD, onRequestUserChatAdd);
}

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestUserChatListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_user_chat/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestUserChatAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail/add', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { message: true, data: response };
};

function* onRequestUserChatList(data) {
  try {
    const response = yield call(onRequestUserChatListAsync, data.payload);
    yield put(RequestUserChatListSuccess(response.data));
  } catch (error) {
    yield put(RequestUserChatListError(error));
  }
}

function* onRequestUserChatAdd(data) {
  try {
    const response = yield call(onRequestUserChatAddAsync, data.payload);
    if (response.data.status === true) {
      NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
    } else if (response.data.status === 'error') {
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
    }
    yield put(RequestUserChatAddSuccess(response.data));
  } catch (error) {
    alert(error)
    yield put(RequestUserChatAddError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchRequestUserChatList),
    fork(watchRequestUserChatAdd)
  ]);
}