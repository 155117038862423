import {
  SEARCH_GEO_COUNTRY,
  SEARCH_GEO_COUNTRY_SUCCESS,
  SEARCH_GEO_COUNTRY_ERROR,
  SEARCH_ENTERPRISE_TYPE,
  SEARCH_ENTERPRISE_TYPE_SUCCESS,
  SEARCH_ENTERPRISE_TYPE_ERROR,
  SEARCH_PRODUCT_TYPE,
  SEARCH_PRODUCT_TYPE_SUCCESS,
  SEARCH_PRODUCT_TYPE_ERROR,
  SEARCH_DELIVERY_TERM,
  SEARCH_DELIVERY_TERM_SUCCESS,
  SEARCH_DELIVERY_TERM_ERROR,
  SEARCH_ARTICLE_GROUP,
  SEARCH_ARTICLE_GROUP_SUCCESS,
  SEARCH_ARTICLE_GROUP_ERROR,
  SEARCH_CURRENCY,
  SEARCH_CURRENCY_SUCCESS,
  SEARCH_CURRENCY_ERROR,
  SEARCH_QUOTE_EXPIRATION,
  SEARCH_QUOTE_EXPIRATION_SUCCESS,
  SEARCH_QUOTE_EXPIRATION_ERROR,
  SEARCH_INCOTERM,
  SEARCH_INCOTERM_SUCCESS,
  SEARCH_INCOTERM_ERROR,
  SEARCH_TRANSPORT_TYPE,
  SEARCH_TRANSPORT_TYPE_SUCCESS,
  SEARCH_TRANSPORT_TYPE_ERROR,
  SEARCH_FILE_TYPE,
  SEARCH_FILE_TYPE_SUCCESS,
  SEARCH_FILE_TYPE_ERROR,
  SEARCH_REQUEST_PROCESS_TYPE,
  SEARCH_REQUEST_PROCESS_TYPE_SUCCESS,
  SEARCH_REQUEST_PROCESS_TYPE_ERROR,
  SEARCH_REQUEST_ARTICLE_TYPE,
  SEARCH_REQUEST_ARTICLE_TYPE_SUCCESS,
  SEARCH_REQUEST_ARTICLE_TYPE_ERROR
} from '../actions';
 
const INIT_STATE = {
  modal: false,
  init:true,
  geo_country: {
    data: [],
    loading: true,
    message: ''
  },
  enterprise_type: {
    data: [],
    loading: true,
    message: ''
  },
  product_type: {
    data: [],
    loading: true,
    message: ''
  },
  article_group: {
    data: [],
    loading: true,
    message: ''
  },
  delivery_term: {
    data: [],
    loading: true,
    message: ''
  },
  currency: {
    data: [],
    loading: true,
    message: ''
  },
  quote_expiration: {
    data: [],
    loading: true,
    message: ''
  },
  incoterm: {
    data: [],
    loading: true,
    message: ''
  },
  transport_type: {
    data: [],
    loading: true,
    message: ''
  },
  file_type: {
    data: [],
    loading: true,
    message: ''
  },
  request_process_type: {
    data: [],
    loading: true,
    message: ''
  },
  request_article_type: {
    data: [],
    loading: true,
    message: ''
  },
  value:{
    quote_expiration: false,
    geo_country: false,
    enterprise_type: false,
    product_type: false, 
    article_group: false,
    delivery_term: false,
    currency: false,
    incoterm: false,
    transport_type: false,
    file_type: false,
    request_process_type: false,
    priority:'Si',
    code: '',
    request_article_type: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCH_GEO_COUNTRY:
      return {
        ...state,
        init:false,
        geo_country:{
          ...state.geo_country,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_GEO_COUNTRY_SUCCESS:
      return {
        ...state,
        geo_country:{
          ...state.geo_country,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_GEO_COUNTRY_ERROR:
      return {
        ...state,
        geo_country:{
          ...state.geo_country,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_ENTERPRISE_TYPE:
      return {
        ...state,
        init:false,
        enterprise_type:{
          ...state.enterprise_type,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_ENTERPRISE_TYPE_SUCCESS:
      return {
        ...state,
        enterprise_type:{
          ...state.enterprise_type,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_ENTERPRISE_TYPE_ERROR:
      return {
        ...state,
        enterprise_type:{
          ...state.enterprise_type,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_PRODUCT_TYPE:
      return {
        ...state,
        init:false,
        product_type:{
          ...state.product_type,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        product_type:{
          ...state.product_type,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_PRODUCT_TYPE_ERROR:
      return {
        ...state,
        product_type:{
          ...state.product_type,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_DELIVERY_TERM:
      return {
        ...state,
        init:false,
        delivery_term:{
          ...state.delivery_term,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_DELIVERY_TERM_SUCCESS:
      return {
        ...state,
        delivery_term:{
          ...state.delivery_term,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_DELIVERY_TERM_ERROR:
      return {
        ...state,
        delivery_term:{
          ...state.delivery_term,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_ARTICLE_GROUP:
      return {
        ...state,
        init:false,
        article_group:{
          ...state.article_group,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_ARTICLE_GROUP_SUCCESS:
      return {
        ...state,
        article_group:{
          ...state.article_group,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_ARTICLE_GROUP_ERROR:
      return {
        ...state,
        article_group:{
          ...state.article_group,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_CURRENCY:
      return {
        ...state,
        init:false,
        currency:{
          ...state.currency,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_CURRENCY_SUCCESS:
      return {
        ...state,
        currency:{
          ...state.currency,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_CURRENCY_ERROR:
      return {
        ...state,
        currency:{
          ...state.currency,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_QUOTE_EXPIRATION:
      return {
        ...state,
        init:false,
        quote_expiration:{
          ...state.quote_expiration,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_QUOTE_EXPIRATION_SUCCESS:
      return {
        ...state,
        quote_expiration:{
          ...state.quote_expiration,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_QUOTE_EXPIRATION_ERROR:
      return {
        ...state,
        quote_expiration:{
          ...state.quote_expiration,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_INCOTERM:
      return {
        ...state,
        init:false,
        incoterm:{
          ...state.quote_expiration,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_INCOTERM_SUCCESS:
      return {
        ...state,
        incoterm:{
          ...state.incoterm,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_INCOTERM_ERROR:
      return {
        ...state,
        incoterm:{
          ...state.incoterm,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_TRANSPORT_TYPE:
      return {
        ...state,
        init:false,
        transport_type:{
          ...state.transport_type,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_TRANSPORT_TYPE_SUCCESS:
      return {
        ...state,
        transport_type:{
          ...state.transport_type,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_TRANSPORT_TYPE_ERROR:
      return {
        ...state,
        transport_type:{
          ...state.transport_type,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_FILE_TYPE:
      return {
        ...state,
        init:false,
        file_type:{
          ...state.file_type,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_FILE_TYPE_SUCCESS:
      return {
        ...state,
        file_type:{
          ...state.file_type,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_FILE_TYPE_ERROR:
      return {
        ...state,
        file_type:{
          ...state.file_type,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_REQUEST_PROCESS_TYPE:
      return {
        ...state,
        init:false,
        request_process_type:{
          ...state.request_process_type,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_REQUEST_PROCESS_TYPE_SUCCESS:
      return {
        ...state,
        request_process_type:{
          ...state.request_process_type,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_REQUEST_PROCESS_TYPE_ERROR:
      return {
        ...state,
        request_process_type:{
          ...state.request_process_type,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    case SEARCH_REQUEST_ARTICLE_TYPE:
      return {
        ...state,
        init:false,
        request_article_type:{
          ...state.request_article_type,
          loading: true,
          data:[],
          message: ''
        }
      }
    case SEARCH_REQUEST_ARTICLE_TYPE_SUCCESS:
      return {
        ...state,
        request_article_type:{
          ...state.request_article_type,
          loading: false,
          data: action.payload,
          message: ''
        }
      }
    case SEARCH_REQUEST_ARTICLE_TYPE_ERROR:
      return {
        ...state,
        request_article_type:{
          ...state.request_article_type,
          loading: false,
          data: [],
          message: 'No se encotraron datos'
        }
      }
    default:
      return { ...state };
  }
};