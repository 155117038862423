import { createStructuredSelector } from 'reselect'

import {
  REQUEST_GEO_COUNTRY_LIST,
  REQUEST_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_GEO_COUNTRY_LIST_ERROR,
  REQUEST_GEO_COUNTRY_PAGE_SET,
  REQUEST_GEO_COUNTRY_SEARCH_SET,
  REQUEST_GEO_COUNTRY_SEARCH_CHANGE,
  REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST,
  REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST_SUCCESS,
  REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST_ERROR,
  REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_GEO_COUNTRY_ADD_SET,
  REQUEST_GEO_COUNTRY_ADD_MODAL_SET,
  REQUEST_GEO_COUNTRY_ADD,
  REQUEST_GEO_COUNTRY_ADD_SUCCESS,
  REQUEST_GEO_COUNTRY_ADD_ERROR,
  REQUEST_GEO_COUNTRY_ADD_CHANGE,
  REQUEST_GEO_COUNTRY_DETAIL,
  REQUEST_GEO_COUNTRY_DETAIL_SUCCESS,
  REQUEST_GEO_COUNTRY_DETAIL_ERROR,
  REQUEST_GEO_COUNTRY_VIEW_MODAL_SET,
  REQUEST_GEO_COUNTRY_EDIT_CHANGE,
  REQUEST_GEO_COUNTRY_EDIT_MODAL_SET,
  REQUEST_GEO_COUNTRY_UPDATE,
  REQUEST_GEO_COUNTRY_UPDATE_SUCCESS,
  REQUEST_GEO_COUNTRY_UPDATE_ERROR,
  REQUEST_GEO_COUNTRY_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_geo_country';

export const RequestGeoCountryList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_GEO_COUNTRY_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestGeoCountryListSuccess = (data) => ({
  type: REQUEST_GEO_COUNTRY_LIST_SUCCESS,
  payload: data
});

export const RequestGeoCountryListError = (error) => ({
  type: REQUEST_GEO_COUNTRY_LIST_ERROR,
  payload: error
});

export const RequestGeoCountryPageSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_PAGE_SET,
  payload: data
});

export const RequestGeoCountrySearchSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_SET,
  payload: data
});

export const RequestGeoCountrySearchChangeSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_CHANGE,
  payload: data
});

export const RequestGeoCountrySearchRequestList = (data) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST,
  payload: data
});

export const RequestGeoCountrySearchRequestListSuccess = (message) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const RequestGeoCountrySearchRequestListError = (error) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const RequestGeoCountrySearchGeoCountryList = (data) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestGeoCountrySearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestGeoCountrySearchGeoCountryListError = (error) => ({
  type: REQUEST_GEO_COUNTRY_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const RequestGeoCountryAddChangeSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_ADD_CHANGE,
  payload: data
});

export const RequestGeoCountryAddSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_ADD_SET,
  payload: data
});

export const RequestGeoCountryAddModalSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_ADD_MODAL_SET,
  payload: data
});

export const RequestGeoCountryAdd = (data) => ({
  type: REQUEST_GEO_COUNTRY_ADD,
  payload: data
});

export const RequestGeoCountryAddSuccess = (message) => ({
  type: REQUEST_GEO_COUNTRY_ADD_SUCCESS,
  payload: message
});

export const RequestGeoCountryAddError = (error) => ({
  type: REQUEST_GEO_COUNTRY_ADD_ERROR,
  payload: error
});

export const RequestGeoCountryDetail = (data) => ({
  type: REQUEST_GEO_COUNTRY_DETAIL,
  payload: data
});

export const RequestGeoCountryDetailSuccess = (data) => ({
  type: REQUEST_GEO_COUNTRY_DETAIL_SUCCESS,
  payload: data
});

export const RequestGeoCountryDetailError = (error) => ({
  type: REQUEST_GEO_COUNTRY_DETAIL_ERROR,
  payload: error
});

export const RequestGeoCountryViewModalSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_VIEW_MODAL_SET,
  payload: data
});

export const RequestGeoCountryEditModalSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_EDIT_MODAL_SET,
  payload: data
});

export const RequestGeoCountryEditChangeSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_EDIT_CHANGE,
  payload: data
});

export const RequestGeoCountryUpdate = (data) => ({
  type: REQUEST_GEO_COUNTRY_UPDATE,
  payload: data
});

export const RequestGeoCountryUpdateSuccess = (message) => ({
  type: REQUEST_GEO_COUNTRY_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestGeoCountryUpdateError = (error) => ({
  type: REQUEST_GEO_COUNTRY_UPDATE_ERROR,
  payload: error
});

export const RequestGeoCountryViewTabSet = (data) => ({
  type: REQUEST_GEO_COUNTRY_VIEW_TAB_SET,
  payload: data
});

const requestGeoCountry = (state) => state[NAME];

export const RequestGeoCountrySelector = createStructuredSelector({
  requestGeoCountry
})
