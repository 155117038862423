import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_MESSAGE_LIST,
  REQUEST_MESSAGE_SEARCH_REQUEST_LIST,
  REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_MESSAGE_ADD,
  REQUEST_MESSAGE_DETAIL,
  REQUEST_MESSAGE_UPDATE,
} from '../actions';
import {
  RequestMessageListSuccess,
  RequestMessageListError,
  RequestMessageSearchRequestListSuccess,
  RequestMessageSearchRequestListError,
  RequestMessageSearchGeoCountryListSuccess,
  RequestMessageSearchGeoCountryListError,
  RequestMessageAddSuccess,
  RequestMessageAddError,
  RequestMessageDetailSuccess,
  RequestMessageDetailError,
  RequestMessageUpdateSuccess,
  RequestMessageUpdateError
} from '../actions';
 
export function* watchRequestMessageList() {
  yield takeEvery(REQUEST_MESSAGE_LIST, onRequestMessageList);
}

export function* watchRequestMessageSearchRequestList() {
  yield takeEvery(REQUEST_MESSAGE_SEARCH_REQUEST_LIST, onRequestMessageSearchRequestList);
}

export function* watchRequestMessageSearchGeoCountryList() {
  yield takeEvery(REQUEST_MESSAGE_SEARCH_GEO_COUNTRY_LIST, onRequestMessageSearchGeoCountryList);
}

export function* watchRequestMessageAdd() {
  yield takeEvery(REQUEST_MESSAGE_ADD, onRequestMessageAdd);
}

export function* watchRequestMessageDetail() {
  yield takeEvery(REQUEST_MESSAGE_DETAIL, onRequestMessageDetail);
}

export function* watchRequestMessageUpdate() {
  yield takeEvery(REQUEST_MESSAGE_UPDATE, onRequestMessageUpdate);
}

const onRequestMessageListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_message/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestMessageUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_message/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestMessageSearchRequestListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestMessageSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestMessageAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_message/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestMessageDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_message/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestMessageList(data) {
  try {
    const response = yield call(onRequestMessageListAsync, data.payload);
    yield put(RequestMessageListSuccess(response.data));
  } catch (error) {
    yield put(RequestMessageListError(error));
  }
}

function* onRequestMessageSearchRequestList(data) {
  try {
    console.log('onRequestMessageSearchRequestList',data.payload)
    const response = yield call(onRequestMessageSearchRequestListAsync, data.payload);
    yield put(RequestMessageSearchRequestListSuccess(response.data));
  } catch (error) {
    yield put(RequestMessageSearchRequestListError(error));
  }
}

function* onRequestMessageSearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestMessageSearchGeoCountryListAsync, data.payload);
    yield put(RequestMessageSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestMessageSearchGeoCountryListError(error));
  }
}

function* onRequestMessageAdd(data) {
  try {
      const response = yield call(onRequestMessageAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestMessageAddSuccess(response.data));
  } catch (error) {
      yield put(RequestMessageAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestMessageDetail(data) {
  try {
    const response = yield call(onRequestMessageDetailAsync, data.payload);
    yield put(RequestMessageDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestMessageDetailError(error));
  }
}

function* onRequestMessageUpdate(data) {
  try {
      const response = yield call(onRequestMessageUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestMessageUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestMessageUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestMessageList),
    fork(watchRequestMessageSearchRequestList),
    fork(watchRequestMessageSearchGeoCountryList),
    fork(watchRequestMessageAdd),
    fork(watchRequestMessageDetail),
    fork(watchRequestMessageUpdate),
  ]);
} 