import { createStructuredSelector } from "reselect";
import {
  UPLOAD_SET,
  UPLOAD_SET_SUCCESS,
  UPLOAD_SET_ERROR,
  UPLOAD_RESET
} from "../actions";

export const NAME = "upload";

const upload = (state) => state[NAME];

export const uploadSet = (file) => (
  {
    type: UPLOAD_SET,
    payload: { file },
  }
);

export const uploadSetSuccess = (items) => ({
  type: UPLOAD_SET_SUCCESS,
  payload: items,
});

export const uploadSetError = (error) => ({
  type: UPLOAD_SET_ERROR,
  payload: error,
});

export const uploadReset= () => ({
  type: UPLOAD_RESET
});

export const UploadSelector = createStructuredSelector({
  upload,
});
