import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_HEALTH_REGISTRY_LIST,
  REQUEST_DETAIL_HEALTH_REGISTRY_LIST_SUCCESS,
  REQUEST_DETAIL_HEALTH_REGISTRY_LIST_ERROR,
  REQUEST_DETAIL_HEALTH_REGISTRY_PAGE_SET,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_SET,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_CHANGE,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SET,
  REQUEST_DETAIL_HEALTH_REGISTRY_ADD_MODAL_SET,
  REQUEST_DETAIL_HEALTH_REGISTRY_ADD,
  REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SUCCESS,
  REQUEST_DETAIL_HEALTH_REGISTRY_ADD_ERROR,
  REQUEST_DETAIL_HEALTH_REGISTRY_ADD_CHANGE,
  REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL,
  REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_SUCCESS,
  REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_ERROR,
  REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_MODAL_SET,
  REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_CHANGE,
  REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_MODAL_SET,
  REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE,
  REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_SUCCESS,
  REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_ERROR,
  REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_detail_health_registry';

export const RequestDetailHealthRegistryList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestDetailHealthRegistryListSuccess = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_LIST_SUCCESS,
  payload: data
});

export const RequestDetailHealthRegistryListError = (error) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_LIST_ERROR,
  payload: error
});

export const RequestDetailHealthRegistryPageSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_PAGE_SET,
  payload: data
});

export const RequestDetailHealthRegistrySearchSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_SET,
  payload: data
});

export const RequestDetailHealthRegistrySearchChangeSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_CHANGE,
  payload: data
});

export const RequestDetailHealthRegistrySearchGeoCountryList = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestDetailHealthRegistrySearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailHealthRegistrySearchGeoCountryListError = (error) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
});

export const RequestDetailHealthRegistrySearchHealthAgencyList = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST,
  payload: data
});

export const RequestDetailHealthRegistrySearchHealthAgencyListSuccess = (message) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailHealthRegistrySearchHealthAgencyListError = (error) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  payload: error
});

export const RequestDetailHealthRegistryAddChangeSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_ADD_CHANGE,
  payload: data
});

export const RequestDetailHealthRegistryAddSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SET,
  payload: data
});

export const RequestDetailHealthRegistryAddModalSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_ADD_MODAL_SET,
  payload: data
});

export const RequestDetailHealthRegistryAdd = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_ADD,
  payload: data
});

export const RequestDetailHealthRegistryAddSuccess = (message) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_ADD_SUCCESS,
  payload: message
});

export const RequestDetailHealthRegistryAddError = (error) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_ADD_ERROR,
  payload: error
});

export const RequestDetailHealthRegistryDetail = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL,
  payload: data
});

export const RequestDetailHealthRegistryDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailHealthRegistryDetailError = (error) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_DETAIL_ERROR,
  payload: error
});

export const RequestDetailHealthRegistryViewModalSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_MODAL_SET,
  payload: data
});

export const RequestDetailHealthRegistryEditModalSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailHealthRegistryEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_EDIT_CHANGE,
  payload: data
});

export const RequestDetailHealthRegistryUpdate = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE,
  payload: data
});

export const RequestDetailHealthRegistryUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailHealthRegistryUpdateError = (error) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_UPDATE_ERROR,
  payload: error
});

export const RequestDetailHealthRegistryViewTabSet = (data) => ({
  type: REQUEST_DETAIL_HEALTH_REGISTRY_VIEW_TAB_SET,
  payload: data
});

const request_detail_health_registry = (state) => state[NAME];

export const RequestDetailHealthRegistrySelector = createStructuredSelector({
  request_detail_health_registry
})
