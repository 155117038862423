import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_DELIVERY_TERM_LIST,
  REQUEST_DETAIL_DELIVERY_TERM_LIST_SUCCESS,
  REQUEST_DETAIL_DELIVERY_TERM_LIST_ERROR,
  REQUEST_DETAIL_DELIVERY_TERM_PAGE_SET,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_SET,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_CHANGE,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_SUCCESS,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_ERROR,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  REQUEST_DETAIL_DELIVERY_TERM_ADD_SET,
  REQUEST_DETAIL_DELIVERY_TERM_ADD_MODAL_SET,
  REQUEST_DETAIL_DELIVERY_TERM_ADD,
  REQUEST_DETAIL_DELIVERY_TERM_ADD_SUCCESS,
  REQUEST_DETAIL_DELIVERY_TERM_ADD_ERROR,
  REQUEST_DETAIL_DELIVERY_TERM_ADD_CHANGE,
  REQUEST_DETAIL_DELIVERY_TERM_DETAIL,
  REQUEST_DETAIL_DELIVERY_TERM_DETAIL_SUCCESS,
  REQUEST_DETAIL_DELIVERY_TERM_DETAIL_ERROR,
  REQUEST_DETAIL_DELIVERY_TERM_VIEW_MODAL_SET,
  REQUEST_DETAIL_DELIVERY_TERM_EDIT_CHANGE,
  REQUEST_DETAIL_DELIVERY_TERM_EDIT_MODAL_SET,
  REQUEST_DETAIL_DELIVERY_TERM_UPDATE,
  REQUEST_DETAIL_DELIVERY_TERM_UPDATE_SUCCESS,
  REQUEST_DETAIL_DELIVERY_TERM_UPDATE_ERROR,
  REQUEST_DETAIL_DELIVERY_TERM_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_detail_delivery_term';

export const RequestDetailDeliveryTermList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestDetailDeliveryTermListSuccess = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_LIST_SUCCESS,
  payload: data
});

export const RequestDetailDeliveryTermListError = (error) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_LIST_ERROR,
  payload: error
});

export const RequestDetailDeliveryTermPageSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_PAGE_SET,
  payload: data
});

export const RequestDetailDeliveryTermSearchSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_SET,
  payload: data
});

export const RequestDetailDeliveryTermSearchChangeSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_CHANGE,
  payload: data
});

export const RequestDetailDeliveryTermSearchDeliveryTermList = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST,
  payload: data
});

export const RequestDetailDeliveryTermSearchDeliveryTermListSuccess = (message) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_SUCCESS,
  payload: message
});

export const RequestDetailDeliveryTermSearchDeliveryTermListError = (error) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST_ERROR,
  payload: error
});

export const RequestDetailDeliveryTermSearchHealthAgencyList = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST,
  payload: data
});

export const RequestDetailDeliveryTermSearchHealthAgencyListSuccess = (message) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailDeliveryTermSearchHealthAgencyListError = (error) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  payload: error
});

export const RequestDetailDeliveryTermAddChangeSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_ADD_CHANGE,
  payload: data
});

export const RequestDetailDeliveryTermAddSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_ADD_SET,
  payload: data
});

export const RequestDetailDeliveryTermAddModalSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_ADD_MODAL_SET,
  payload: data
});

export const RequestDetailDeliveryTermAdd = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_ADD,
  payload: data
});

export const RequestDetailDeliveryTermAddSuccess = (message) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_ADD_SUCCESS,
  payload: message
});

export const RequestDetailDeliveryTermAddError = (error) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_ADD_ERROR,
  payload: error
});

export const RequestDetailDeliveryTermDetail = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_DETAIL,
  payload: data
});

export const RequestDetailDeliveryTermDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailDeliveryTermDetailError = (error) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_DETAIL_ERROR,
  payload: error
});

export const RequestDetailDeliveryTermViewModalSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_VIEW_MODAL_SET,
  payload: data
});

export const RequestDetailDeliveryTermEditModalSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailDeliveryTermEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_EDIT_CHANGE,
  payload: data
});

export const RequestDetailDeliveryTermUpdate = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_UPDATE,
  payload: data
});

export const RequestDetailDeliveryTermUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailDeliveryTermUpdateError = (error) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_UPDATE_ERROR,
  payload: error
});

export const RequestDetailDeliveryTermViewTabSet = (data) => ({
  type: REQUEST_DETAIL_DELIVERY_TERM_VIEW_TAB_SET,
  payload: data
});

const request_detail_delivery_term = (state) => state[NAME];

export const RequestDetailDeliveryTermSelector = createStructuredSelector({
  request_detail_delivery_term
})
