import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_DETAIL_DELIVERY_TERM_LIST,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST,
  REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_DELIVERY_TERM_ADD,
  REQUEST_DETAIL_DELIVERY_TERM_DETAIL,
  REQUEST_DETAIL_DELIVERY_TERM_UPDATE,
} from '../actions';
import {
  RequestDetailDeliveryTermListSuccess,
  RequestDetailDeliveryTermListError,
  RequestDetailDeliveryTermSearchDeliveryTermListSuccess,
  RequestDetailDeliveryTermSearchDeliveryTermListError,
  RequestDetailDeliveryTermSearchHealthAgencyListSuccess,
  RequestDetailDeliveryTermSearchHealthAgencyListError,
  RequestDetailDeliveryTermAddSuccess,
  RequestDetailDeliveryTermAddError,
  RequestDetailDeliveryTermDetailSuccess,
  RequestDetailDeliveryTermDetailError,
  RequestDetailDeliveryTermUpdateSuccess,
  RequestDetailDeliveryTermUpdateError
} from '../actions';
 
export function* watchRequestDetailDeliveryTermList() {
  yield takeEvery(REQUEST_DETAIL_DELIVERY_TERM_LIST, onRequestDetailDeliveryTermList);
}

export function* watchRequestDetailDeliveryTermSearchHealthAgencyList() {
  yield takeEvery(REQUEST_DETAIL_DELIVERY_TERM_SEARCH_HEALTH_AGENCY_LIST, onRequestDetailDeliveryTermSearchHealthAgencyList);
}

export function* watchRequestDetailDeliveryTermSearchDeliveryTermList() {
  yield takeEvery(REQUEST_DETAIL_DELIVERY_TERM_SEARCH_DELIVERY_TERM_LIST, onRequestDetailDeliveryTermSearchDeliveryTermList);
}

export function* watchRequestDetailDeliveryTermAdd() {
  yield takeEvery(REQUEST_DETAIL_DELIVERY_TERM_ADD, onRequestDetailDeliveryTermAdd);
}

export function* watchRequestDetailDeliveryTermDetail() {
  yield takeEvery(REQUEST_DETAIL_DELIVERY_TERM_DETAIL, onRequestDetailDeliveryTermDetail);
}

export function* watchRequestDetailDeliveryTermUpdate() {
  yield takeEvery(REQUEST_DETAIL_DELIVERY_TERM_UPDATE, onRequestDetailDeliveryTermUpdate);
}

const onRequestDetailDeliveryTermListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_delivery_term/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailDeliveryTermUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_delivery_term/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}


const onRequestDetailDeliveryTermAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_delivery_term/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailDeliveryTermSearchDeliveryTermListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/delivery_term', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailDeliveryTermSearchHealthAgencyListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/health_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailDeliveryTermDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_delivery_term/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestDetailDeliveryTermList(data) {
  try {
    const response = yield call(onRequestDetailDeliveryTermListAsync, data.payload);
    yield put(RequestDetailDeliveryTermListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailDeliveryTermListError(error));
  }
}

function* onRequestDetailDeliveryTermSearchHealthAgencyList(data) {
  try {
    const response = yield call(onRequestDetailDeliveryTermSearchHealthAgencyListAsync, data.payload);
    yield put(RequestDetailDeliveryTermSearchHealthAgencyListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailDeliveryTermSearchHealthAgencyListError(error));
  }
}

function* onRequestDetailDeliveryTermSearchDeliveryTermList(data) {
  try {
    const response = yield call(onRequestDetailDeliveryTermSearchDeliveryTermListAsync, data.payload);
    yield put(RequestDetailDeliveryTermSearchDeliveryTermListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailDeliveryTermSearchDeliveryTermListError(error));
  }
}

function* onRequestDetailDeliveryTermAdd(data) {
  try {
      const response = yield call(onRequestDetailDeliveryTermAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailDeliveryTermAddSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailDeliveryTermAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetailDeliveryTermDetail(data) {
  try {
    const response = yield call(onRequestDetailDeliveryTermDetailAsync, data.payload);
    yield put(RequestDetailDeliveryTermDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailDeliveryTermDetailError(error));
  }
}

function* onRequestDetailDeliveryTermUpdate(data) {
  try {
      const response = yield call(onRequestDetailDeliveryTermUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailDeliveryTermUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailDeliveryTermUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailDeliveryTermList),
    fork(watchRequestDetailDeliveryTermSearchDeliveryTermList),
    fork(watchRequestDetailDeliveryTermSearchHealthAgencyList),
    fork(watchRequestDetailDeliveryTermAdd),
    fork(watchRequestDetailDeliveryTermDetail),
    fork(watchRequestDetailDeliveryTermUpdate),
  ]);
} 