import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import {
  REQUEST_INFO,
  REQUEST_UPDATE
} from '../actions';

import {
  RequestInfoSuccess,
  RequestInfoError,
  RequestUpdateSuccess,
  RequestUpdateError
} from '../actions';

export function* watchRequestInfo() {
  yield takeEvery(REQUEST_INFO, onRequestInfo);
}

export function* watchRequestUpdate() {
  yield takeEvery(REQUEST_UPDATE, onRequestUpdate);
}

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestInfoAsync  = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request/info', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data.payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request/update', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { message: true, data: response };
};

function* onRequestInfo(data) {
  try {
    const response = yield call(onRequestInfoAsync, data);
    yield put(RequestInfoSuccess(response.data.data));
  } catch (error) {
    yield put(RequestInfoError(error));
  }
}

function* onRequestUpdate(data) {
  try {
    const response = yield call(onRequestUpdateAsync, data.payload);
    if (response.data.status === true) {
      NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
    } else if (response.data.status === 'error') {
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
    }
    yield put(RequestUpdateSuccess(response.data.data));
  } catch (error) {
    alert(error)
    yield put(RequestUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestInfo),
    fork(watchRequestUpdate)
  ]);
}