import { createStructuredSelector } from 'reselect'

import {
  REQUEST_INFO,
  REQUEST_INFO_SUCCESS,
  REQUEST_INFO_ERROR,
  REQUEST_PAGE_SET,
  REQUEST_EDIT_MODAL_SET,
  REQUEST_EDIT_CHANGE,
  REQUEST_UPDATE,
  REQUEST_UPDATE_SUCCESS,
  REQUEST_UPDATE_ERROR
} from '../actions';

export const NAME = 'request';

export const RequestInfo = (data) => ({
  type: REQUEST_INFO,
  payload: data
});

export const RequestInfoSuccess = (data) => ({
  type: REQUEST_INFO_SUCCESS,
  payload: data
});

export const RequestInfoError = (error) => ({
  type: REQUEST_INFO_ERROR,
  payload: error
});

export const RequestPageSet = (data) => ({
  type: REQUEST_PAGE_SET,
  payload: data
});

export const RequestEditModalSet = (data) => ({
  type: REQUEST_EDIT_MODAL_SET,
  payload: data
});

export const RequestEditChangeSet = (data) => ({
  type: REQUEST_EDIT_CHANGE,
  payload: data
});

export const RequestUpdate = (data) => ({
  type: REQUEST_UPDATE,
  payload: data
});

export const RequestUpdateSuccess = (data) => ({
  type: REQUEST_UPDATE_SUCCESS,
  payload: data
});

export const RequestUpdateError = (error) => ({
  type: REQUEST_UPDATE_ERROR,
  payload: error
});

const request = (state) => state[NAME];

export const RequestSelector = createStructuredSelector({
  request
})
