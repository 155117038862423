import { createStructuredSelector } from 'reselect'

import {
  SUPPLIER_EMAIL_LIST,
  SUPPLIER_EMAIL_LIST_SUCCESS,
  SUPPLIER_EMAIL_LIST_ERROR,
  SUPPLIER_EMAIL_PAGE_SET,
  SUPPLIER_EMAIL_SEARCH_SET,
  SUPPLIER_EMAIL_SEARCH_CHANGE,
  SUPPLIER_EMAIL_SEARCH_REQUEST_LIST,
  SUPPLIER_EMAIL_SEARCH_REQUEST_LIST_SUCCESS,
  SUPPLIER_EMAIL_SEARCH_REQUEST_LIST_ERROR,
  SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST,
  SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR,
  SUPPLIER_EMAIL_ADD_SET,
  SUPPLIER_EMAIL_ADD_MODAL_SET,
  SUPPLIER_EMAIL_ADD,
  SUPPLIER_EMAIL_ADD_SUCCESS,
  SUPPLIER_EMAIL_ADD_ERROR,
  SUPPLIER_EMAIL_ADD_CHANGE,
  SUPPLIER_EMAIL_DETAIL,
  SUPPLIER_EMAIL_DETAIL_SUCCESS,
  SUPPLIER_EMAIL_DETAIL_ERROR,
  SUPPLIER_EMAIL_VIEW_MODAL_SET,
  SUPPLIER_EMAIL_EDIT_CHANGE,
  SUPPLIER_EMAIL_EDIT_MODAL_SET,
  SUPPLIER_EMAIL_UPDATE,
  SUPPLIER_EMAIL_UPDATE_SUCCESS,
  SUPPLIER_EMAIL_UPDATE_ERROR,
  SUPPLIER_EMAIL_VIEW_TAB_SET
} from '../actions';

export const NAME = 'supplier_email';

export const SupplierEmailList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: SUPPLIER_EMAIL_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const SupplierEmailListSuccess = (data) => ({
  type: SUPPLIER_EMAIL_LIST_SUCCESS,
  payload: data
});

export const SupplierEmailListError = (error) => ({
  type: SUPPLIER_EMAIL_LIST_ERROR,
  payload: error
});

export const SupplierEmailPageSet = (data) => ({
  type: SUPPLIER_EMAIL_PAGE_SET,
  payload: data
});

export const SupplierEmailSearchSet = (data) => ({
  type: SUPPLIER_EMAIL_SEARCH_SET,
  payload: data
});

export const SupplierEmailSearchChangeSet = (data) => ({
  type: SUPPLIER_EMAIL_SEARCH_CHANGE,
  payload: data
});

export const SupplierEmailSearchRequestList = (data) => ({
  type: SUPPLIER_EMAIL_SEARCH_REQUEST_LIST,
  payload: data
});

export const SupplierEmailSearchRequestListSuccess = (message) => ({
  type: SUPPLIER_EMAIL_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const SupplierEmailSearchRequestListError = (error) => ({
  type: SUPPLIER_EMAIL_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const SupplierEmailSearchGeoCountryList = (data) => ({
  type: SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const SupplierEmailSearchGeoCountryListSuccess = (message) => ({
  type: SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const SupplierEmailSearchGeoCountryListError = (error) => ({
  type: SUPPLIER_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const SupplierEmailAddChangeSet = (data) => ({
  type: SUPPLIER_EMAIL_ADD_CHANGE,
  payload: data
});

export const SupplierEmailAddSet = (data) => ({
  type: SUPPLIER_EMAIL_ADD_SET,
  payload: data
});

export const SupplierEmailAddModalSet = (data) => ({
  type: SUPPLIER_EMAIL_ADD_MODAL_SET,
  payload: data
});

export const SupplierEmailAdd = (data) => ({
  type: SUPPLIER_EMAIL_ADD,
  payload: data
});

export const SupplierEmailAddSuccess = (message) => ({
  type: SUPPLIER_EMAIL_ADD_SUCCESS,
  payload: message
});

export const SupplierEmailAddError = (error) => ({
  type: SUPPLIER_EMAIL_ADD_ERROR,
  payload: error
});

export const SupplierEmailDetail = (data) => ({
  type: SUPPLIER_EMAIL_DETAIL,
  payload: data
});

export const SupplierEmailDetailSuccess = (data) => ({
  type: SUPPLIER_EMAIL_DETAIL_SUCCESS,
  payload: data
});

export const SupplierEmailDetailError = (error) => ({
  type: SUPPLIER_EMAIL_DETAIL_ERROR,
  payload: error
});

export const SupplierEmailViewModalSet = (data) => ({
  type: SUPPLIER_EMAIL_VIEW_MODAL_SET,
  payload: data
});

export const SupplierEmailEditModalSet = (data) => ({
  type: SUPPLIER_EMAIL_EDIT_MODAL_SET,
  payload: data
});

export const SupplierEmailEditChangeSet = (data) => ({
  type: SUPPLIER_EMAIL_EDIT_CHANGE,
  payload: data
});

export const SupplierEmailUpdate = (data) => ({
  type: SUPPLIER_EMAIL_UPDATE,
  payload: data
});

export const SupplierEmailUpdateSuccess = (message) => ({
  type: SUPPLIER_EMAIL_UPDATE_SUCCESS,
  payload: message.message
});

export const SupplierEmailUpdateError = (error) => ({
  type: SUPPLIER_EMAIL_UPDATE_ERROR,
  payload: error
});

export const SupplierEmailViewTabSet = (data) => ({
  type: SUPPLIER_EMAIL_VIEW_TAB_SET,
  payload: data
});

const supplier_email = (state) => state[NAME];

export const SupplierEmailSelector = createStructuredSelector({
  supplier_email
})
